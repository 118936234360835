import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {closeModalChooseGroup, closeModalError, closeModalPurchase} from "../store/actions/actionModal";
import {store} from "../store/Store";
import {CloseWindow, Modal} from "../common/CommonStyle";
import {Button, Select} from "../common/style/Style";
import { pressKeyEsc} from "../common/Const";
import {ModalContent, ModalContentContainer} from "./ModalStyle";
import {useEffect, useState} from "react";
import {fetchGroup} from "../store/actions/api/actionApiGroup";
import {fetchMovePurchases} from "../store/actions/api/actionApiPurchase";

const ModalButton = styled(Button)`
  width: 70px;
`

const ModalSelect = styled(Select)`
  margin-bottom: 10px;
  font-size: 14px;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`

const ModalWindowChooseGroup = () => {
    const dispatch = useDispatch();
    const {modal, groups} = useSelector(() => store.getState())
    const [groupChoose, setGroupChoose] = useState(null);

    useEffect(() =>
    {
        if (modal && modal.type === "MODAL_CHOOSE_GROUP" && !Array.isArray(groups)) {
            dispatch(fetchGroup());
        }
    }, [modal.show])

    if (Array.isArray(modal)) {
        return;
    }

    if (!Array.isArray(groups)) {
        return;
    }

    return (
        <Modal show={modal.show && modal.type === "MODAL_CHOOSE_GROUP"}
               onKeyDown={e => pressKeyEsc(e, () => dispatch(closeModalChooseGroup()))}
        >
            <ModalContent>
                <CloseWindow onClick={() => dispatch(closeModalChooseGroup())}>х</CloseWindow>
                <ModalContentContainer>
                    <ModalSelect
                        onChange={e => setGroupChoose(e.target.value)}
                        defaultValue={modal.data?.id}
                    >
                        {groups.map(group => {
                           return <option key={group.id} value={group.id}>{group.name}</option>
                        })}
                    </ModalSelect>
                    <ModalButton autofocus onClick={() => {
                        dispatch(fetchMovePurchases(modal.data?.id, groupChoose, modal.data?.purchaseIds))
                        dispatch(closeModalChooseGroup());
                    }}>OK</ModalButton>
                </ModalContentContainer>
            </ModalContent>
        </Modal>
    )
}

export default ModalWindowChooseGroup;



