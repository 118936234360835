import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Basket from "../components/Bascket";
import Editor from "../components/Editor";
import {ToolBarActionContainer} from "../../common/CommonStyle";
import {useDispatch, useSelector} from "react-redux";
import {showModalGroup} from "../../store/actions/actionModal";
import {store} from "../../store/Store";
import {useEffect, useRef, useState} from "react";
import {deleteGroup} from "../../store/actions/api/actionApiGroup";
import {isMobile, pressKeyEnter} from "../../common/Const";
import {P} from "../../common/style/Style";
import {GroupCardColor, HeaderColor} from "../../common/Color";

const GroupCardContainer = styled.article`
  padding: 10px 10px;
  display: flex;
  border-radius: 10px;
  background-color: ${GroupCardColor.backgroundColor};
  height: 100%;
  align-items: center;
  transition: background-color .4s;
  color:  ${GroupCardColor.colorText};
  
  ${props => props.isSelfGroup === true ? 'background-color: ' +  GroupCardColor.backgroundColorSelf + ";" : ''}

  box-shadow: ${props => props.isSelfGroup === true ?  GroupCardColor.boxShadowSelf : GroupCardColor.boxShadow};

  &:hover {
    background-color: ${GroupCardColor.hover};
    ${props => props.isSelfGroup === true ? 'background-color: ' +  GroupCardColor.hoverSelf + ";"  : ''}
    ${isMobile === true ? (props => props.isSelfGroup === true ? 'background-color: ' + GroupCardColor.backgroundColorSelf + ";" : 'background-color: ' + GroupCardColor.backgroundColor + ";") : ''};

  }

  &:active {
    background-color: ${GroupCardColor.active};
    ${props => props.isSelfGroup === true ? 'background-color: '  +  GroupCardColor.activeSelf + ";"  : ''}
  }

  &:focus-visible {
    background-color: ${GroupCardColor.focusVisible};
    ${props => props.isSelfGroup === true ? 'background-color: ' +   GroupCardColor.focusVisibleSelf + ";"  : ''}
    outline: 0;
    border: 0;
  }
`

const GroupCardTitle = styled(P)`
  margin: 0;
  padding: 0 10px;
  color: black;
`

const GroupCardImg = styled.img`
  margin: 0;
  padding: 0;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #61dafb;
  min-width: 20px;
  border: none;
`

const GroupCardCountMessageContainer = styled.div`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 100%;
  background-color: ${HeaderColor.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    width: 22px;
    height: 22px;
  }

`

const GroupCardCountMessage = styled.p`
  margin: 0;
  padding: 0;
  color: white;
  font-size: 10px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const GroupCardBar = styled.div`
  margin-left: auto;
  display: flex;
  gap: 2px;
`


const GroupCard = ({group, setContext}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {modal, user} = useSelector(() => store.getState())
    const [groupName, setGroupName] = useState('');
    const [flag, setFlag] = useState(false);
    const [mTimer, setMTimer] = useState(null)
    const groupRef = useRef(null);
    let timer;
    let context = {};
    let flagContextMenu;

    useEffect(() => {
        setGroupName(group.name)
    }, [group.name]);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
    }, [])

    const handleOutsideClick = (event) => {
        if (event && event.target !== null && groupRef && groupRef.current !== null && !groupRef.current.contains(event.target)) {
            setTimeout(() => {
                closeContextMenu();
            }, 1)
        }
    }

    const closeContextMenu = () => {
        context.show = false
        context.locationY = localStorage.getItem("locationY")
        context.locationX = localStorage.getItem("locationX")
        context.dat = group
        setContext(context)
    }

    function handleEdit(e, group) {
        e.stopPropagation();
        dispatch(showModalGroup(group))
    }

    function touchStart(e) {
            timer = setTimeout(() => {
                context.show = true;

                if (window.innerWidth - e.touches[0].pageX > 140) {
                    context.locationX = (e.touches[0].pageX);
                } else {
                    context.locationX = (e.touches[0].pageX - 140);
                }

                if (window.innerHeight - e.touches[0].pageY > 60) {
                    context.locationY = (e.touches[0].pageY);
                } else {
                    context.locationY = (e.touches[0].pageY - 40)
                }

                localStorage.setItem("locationX", context.locationX)
                localStorage.setItem("locationY", context.locationY)
                context.dat = group
                context.fEdit = (() => dispatch(showModalGroup(group)))
                context.fDel = (() => dispatch(deleteGroup(group.id)))

                setContext(context)
                flagContextMenu = true;
                setFlag(true)
            }, 1000)
        setMTimer(timer);
    }

    function touchEnd(e) {
        clearTimeout(mTimer);
        if (modal.show !== true && context.show !== true && flag !== true ) {
            navigate("/group/" + group.id);
        }
        setFlag(false)
    }
    function touchMove(e) {
        e.stopPropagation();
        clearTimeout(mTimer);
        setFlag(true);
    }

    function mouseUp(e) {
        if (!isMobile) {
            touchEnd(e)
        }
    }

    function delGroup(e, id) {
        e.stopPropagation();
        dispatch(deleteGroup(id));
    }
    if (user.id == null || user.id === undefined) {
        return null;
    }

    return (
        <GroupCardContainer ref={groupRef}
            isSelfGroup={user.id != group.userId}
            tabindex={0}
            onKeyPress={e => pressKeyEnter(e, () => mouseUp(e))}
            onTouchStart={touchStart}
            onTouchEnd={touchEnd}
            onTouchMove={touchMove}
            onClick={mouseUp}>
            {/*<Img/>*/}
            <GroupCardTitle>{groupName}</GroupCardTitle>
            <GroupCardBar>
                <ToolBarActionContainer>
                    <Editor click={e => handleEdit(e, group)}/>
                    <Basket click={e => delGroup(e, group.id)}/>
                </ToolBarActionContainer>
                <GroupCardCountMessageContainer mobile={isMobile}>
                    <GroupCardCountMessage>{group.countPurchase}</GroupCardCountMessage>
                </GroupCardCountMessageContainer>
            </GroupCardBar>
        </GroupCardContainer>
    );
}

export default GroupCard
