import {Settings} from "../../../settings";

export const fetchGroup = () => ({
    type: 'FETCH_GROUP_REQUEST',
    payload: {
        url: Settings.url + '/api/group/list',
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});


export const fetchGroupById = (id) => ({
    type: 'FETCH_GROUP_BY_ID_REQUEST',
    payload: {
        url: Settings.url + '/api/group/' + id,
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});

export const editGroup = (group) => ({
    type: 'FETCH_EDIT_GROUP_REQUEST',
    payload: {
        url: Settings.url + '/api/group/',
        init: {
            method: 'PUT',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify (group)
        }
    }
});

export const addGroup = (group) => ({
    type: 'FETCH_ADD_GROUP_REQUEST',
    payload: {
        url: Settings.url + '/api/group/',
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify (group)
            // body: JSON.stringify ({
            //     id: null,
            //     name: name,
            //     publicGroup: publicGroup,
            //     userId: userId
            // })
        }
    }
});


export const deleteGroup = (id) => ({
    type: 'FETCH_DELETE_GROUP_REQUEST',
    payload: {
        url: Settings.url + '/api/group/' + id,
        init: {
            method: 'DELETE',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    },
    id: id
})
