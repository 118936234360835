import {createSelector} from "reselect";


const groups = state => state.groups;

export const selectGroupById = (id) => createSelector(
    state => state.groups,
    group => {
        if (!Array.isArray(group) || group.length === 0) {
            return null;
        }
        return  group.filter(g => g.id == id)[0];
    }

)
