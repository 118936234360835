import {Container} from "../common/CommonStyle";
import {Button, H1, Input, P, Select} from "../common/style/Style";
import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import styled from "styled-components";
import userImg from "../img/User.svg";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../store/Store";
import {fetchUser, fetchUserUpdate, fetchUserUpdateAvatar} from "../store/actions/api/actionApiUser";
import ArrowBack from "../common/ArrowBack";
import {getTimeZoneText, getTimeZoneZone, timeZoneMap} from "../common/TimeZone";


const ProfileContainer = styled(Container)`
  h1 {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 10px;
  }

  p {
    &:last-child {
      color: green;
      font-size: 12px;
    }
  }
`

const ProfileLinkContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`

const ProfileButtonContainer = styled.div``

const ProfileBody = styled.div`
  display: flex;
  gap: 40px;
`

const ProfileForm = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfileImg = styled.div`
    margin-bottom: 10px;
`

const ProfileAvatar = styled.div`
  display: flex;
  max-width: 250px;

  img {
    object-fit: contain;
  }
`

const Profile = () => {

    const nav = useNavigate();
    const dispatch = useDispatch();
    const {user, modal} = useSelector(() => store.getState());
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [flagSave, setFlagSave] = useState(false);

    const [error, setError] = useState(false);

    const [flagMessage, setFlagMessage] = useState(false);


    useEffect(() => {
        if (user.id === undefined) {
            dispatch(fetchUser())
        }
        setName(user.name);
        setEmail(user.email);
        setTimeZone(user.timeZone)
        setTimeZone("+05:00")
        setFlagMessage(false);


        let flag = false;

        if (modal.type === 'MODAL_ERROR' && modal.show === true) {
            modal.show = false;
            setError(true);
            flag = true;
            setFlagSave(false);
        }

        if (!error && !flag && flagSave) {
            setFlagMessage(true);
        }

    }, [user, modal])

    function navigate() {
        nav("/");
    }

    const save = (e) => {
        let newUser = {};
        newUser.id = user.id;
        newUser.name = name;
        newUser.email = email;
        newUser.img = user.img;
        newUser.timeZone = timeZone;
        dispatch(fetchUserUpdate(newUser));
        setError(false);
        setFlagSave(true)
    }

    const sendFile = (file) => {
        if (file == null || file === undefined) {
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        dispatch(fetchUserUpdateAvatar(formData));

    }

    if (user.id === undefined) {
        return;
    }
    return (
        <ProfileContainer>
            <ProfileLinkContainer>
                <ArrowBack func={navigate}/>
                {/*<Link*/}
                {/*    name={"Назад"}*/}
                {/*    onClick={navigate}*/}
                {/*/>*/}
            </ProfileLinkContainer>

            <H1>Профиль</H1>
            <ProfileBody>
                <ProfileForm>
                    <ProfileImg>
                        <label>
                            <ProfileAvatar>
                                <img src={user.img != null ? user.img : userImg} alt={"ava"}/>
                            </ProfileAvatar>
                            <input type={"file"}
                                   accept="image/png, image/jpeg"
                                   hidden={true}
                                   height={0}
                                   onChange={e => sendFile(e.target.files[0])}/>
                        </label>
                    </ProfileImg>
                    <P>Имя</P>
                    <Input
                        onChange={e => setName(e.target.value)}
                        value={name}
                        disabled={false}
                    />

                    <P>email</P>
                    <Input
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        disabled={false}
                    />
                    <P>Часовой пояс</P>
                    <Select
                        onChange={e => setTimeZone(getTimeZoneZone(e.target.value))}
                        defaultValue={getTimeZoneText(user.timeZone)}
                        // defaultValue={getTimeZoneText(user.timeZone)}
                    >

                        {timeZoneMap.map(timeZone => {
                            return <option key={timeZone.zone} value={timeZone.text}>{timeZone.text}</option>
                        })}
                    </Select>
                </ProfileForm>
            </ProfileBody>
            <ProfileButtonContainer>
                <Button onClick={save}>Сохранить</Button>
            </ProfileButtonContainer>
            {flagMessage && <P>Сохранено успешно</P>}
        </ProfileContainer>
    )
}

export default Profile;
