
export const findUserListInGroupAction = (users) => ({
    type: 'FIND_USER_LIST_IN_GROUP',
    payload: users
})

export const addUserInUserListInGroupAction = (user) => ({
    type: 'ADD_USER_IN_USER_LIST_IN_GROUP',
    payload: user
})

export const deleteUserInUserListInGroupAction = (user) => ({
    type: 'DELETE_USER_IN_USER_LIST_IN_GROUP',
    payload: user
})
