import {combineReducers, createStore} from "redux";
import reducerGroup from "./reducers/reducerGroup";
import reducerApi from "./reducers/reducerApi";
import reducerPurchase from "./reducers/reducerPurchase";
import reducerModalWindow from "./reducers/reducerModalWindow";
import reducerRegistration from "./reducers/reducerRegisrtation";
import reducerUser from "./reducers/reducerUser";
import reducerUserList from "./reducers/reducerUserList";
import reducerUserListInGroup from "./reducers/reducerUserListInGroup";
import reducerModalWarning from "./reducers/reducerModalWarning";
import reducerLoading from "./reducers/reducerLoading";

const initStore = {
    modal: {
        type: "",
        show: false,
        data: []
    },
    api: '',
    groups: [],
    purchase: [],
    registration: null,
    user: {},
    userList: [],
    userInGroup: [],
    warning: {},
    loading: {},
}

export const store = createStore(combineReducers({
    modal: reducerModalWindow,
    api: reducerApi,
    groups: reducerGroup,
    purchase: reducerPurchase,
    registration: reducerRegistration,
    user: reducerUser,
    users: reducerUserList,
    userInGroup: reducerUserListInGroup,
    warning: reducerModalWarning,
    loading: reducerLoading,
}, initStore));
