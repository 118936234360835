import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeLoading, showLoading} from "../store/actions/actionLoading";
import {TitleStyle} from "../common/CommonStyle";
import {PurchaseButton, PurchaseListContainer, PurchaseListUl} from "../main/purchase/PurchaseList";
import {Settings} from "../settings";
import PublicPurchaseCard from "./PublicPurchaseCard";
import {addPurchaseAction} from "../store/actions/actionPurchace";
import {store} from "../store/Store";
import styled from "styled-components";


const ErrorPage = styled.div`
  width: 100%;
  height: 100vh;
  color: gray;
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 760px) {
    font-size: 20px;
  }

`

const Purchases = ({purchase, timeZone}) => {
    if (purchase?.length > 0) {
        return purchase.map(card => {
            return (
                <li>
                    <PublicPurchaseCard key={card.id} card={card} timeZone={timeZone}/>
                </li>)
        });
    }
}

const PublicList = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({error: false, body: {}})
    const {purchase} = useSelector(() => store.getState())

    useEffect(() => {
        dispatch(showLoading());
        try {
            fetch(Settings.url + '/api/public/group/' + id,
                {
                    method: 'GET',
                    signal: AbortSignal.timeout(500),
                    headers: {'Content-type': 'application/json'},
                    // cors: nocors
                }).then((response) => {
                dispatch(closeLoading());
                if (response.status === 200) {
                    response.json().then(result => {
                        setData({error: false, body: result})
                        result.cards.forEach(card => {
                            dispatch(addPurchaseAction(card))
                        })
                    }
                    );
                    return;
                }
                setData({error: true, body: ""})
            })
        } catch (e) {
            dispatch(closeLoading());
            setData({error: true, body: ""})
        }
    }, [])

    const content = () => {
        if (data.error) {
            return  (
                <ErrorPage>
                    <p>Страница не найдена</p>
                </ErrorPage>
            )
        }
        if (data.body?.groupName) {
            return (
                <PurchaseListContainer>
                    <TitleStyle>{data.body?.groupName}</TitleStyle>
                    {/*<PurchaseButton onClick={() => dispatch(showModalAddPurchase(id))}>+ Добавить дело</PurchaseButton>*/}
                    <PurchaseListUl>
                        <Purchases purchase={purchase} timeZone={data.body.timeZone}/>
                    </PurchaseListUl>
                </PurchaseListContainer>
            )
        }
    }

    return content()
}
export default PublicList
