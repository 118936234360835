import styled from "styled-components";
import Linkify from 'react-linkify';

const CheckboxText = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 1px;

  white-space: break-spaces;
  @media (max-width: 480px) {
    font-size: 20px;
    font-weight: 400;
  }
    `

const Label = styled.label`
    //  cursor: pointer;
      margin: 0;
      display: block;
    `

const Check = styled.div`
  position: relative;
  user-select: none;
  padding-left: 20px;

  transition: visibility 1.4s, opacity 1.4s;
  ${props => props.disable === true ? 'color: gray' : ""};

  @media (max-width: 480px) {
    padding-left: 30px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: -2px;
    //transform: translateY(-50%);
    transform: translateY(-20%);
    stroke-dasharray: 50px;
    fill: none;
    stroke-dashoffset: 0px;

    @media (max-width: 480px) {
      transform: translateY(-35%);
      left: -5px;
      width: 40px;
      height: 40px;
    }

    path {
      stroke: ${props => props.disable === true ? 'gray' : "blue"};
    }

    stroke-dashoffset: ${props => props.checked === true ? '0px' : "50px"};

    transition: stroke-dashoffset .4s;
  }

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    //transform: translateY(-50%);
    transform: translateY(0%);
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-radius: 100%;
    @media (max-width: 480px) {
      transform: translateY(-25%);
      width: 25px;
      height: 25px;
    }
  }
  
  div {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    //transform: translateY(-50%);
    transform: translateY(0%);
    width: 30px;
    height: 25px;
    z-index: 200;
    border: 0;
    outline: 0;
    
    &:hover {
      cursor: pointer
    }
    
  }
`

const Checkbox = ({text, checked, disable, click}) => {

    return (
        <Label>
            <Check disable={disable} checked={checked}>
                <div onClick={click}/>
                <Linkify>
                    <CheckboxText>{text}</CheckboxText>
                </Linkify>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                        d="M10.5,14.7928932 L17.1464466,8.14644661 C17.3417088,7.95118446 17.6582912,7.95118446 17.8535534,8.14644661 C18.0488155,8.34170876 18.0488155,8.65829124 17.8535534,8.85355339 L10.8535534,15.8535534 C10.6582912,16.0488155 10.3417088,16.0488155 10.1464466,15.8535534 L7.14644661,12.8535534 C6.95118446,12.6582912 6.95118446,12.3417088 7.14644661,12.1464466 C7.34170876,11.9511845 7.65829124,11.9511845 7.85355339,12.1464466 L10.5,14.7928932 Z"
                        stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Check>
        </Label>
    )
}
export default Checkbox
