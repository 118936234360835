
export default function reducerRegistration(state = [], action) {
    switch (action.type) {
        case "ENTRY_MESSAGE": {
            return action.payload;
        }
        default:
            return null;
    }
}
