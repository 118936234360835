import {Container} from "../common/CommonStyle";
import {Button, H1, Input, P} from "../common/style/Style";
import React, {useEffect, useState} from "react";
import {Link} from "../common/Link";
import {NavLink, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../store/Store";
import {
    fetchUser,
    fetchUserUpdate,
    fetchUserUpdateAvatar,
    fetchUserUpdatePassword
} from "../store/actions/api/actionApiUser";
import ArrowBack from "../common/ArrowBack";


const ProfileContainer = styled(Container)`
  h1 {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 10px
  }
`

const ProfileLinkContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`

const ProfileButtonContainer = styled.div``

const ProfileBody = styled.div`
  display: flex;
  gap: 40px;
`

const ProfileForm = styled.div``

const Perror = styled(P)`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`
const Psuccess = styled(Perror)`
    color: green;
`

const PasswordPage = () => {

    const nav = useNavigate();
    const dispatch = useDispatch();
    const {user, modal} = useSelector(() => store.getState());
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    const [flagSave, setFlagSave] = useState(false);

    const [error, setError] = useState(false);

    const [flagMessage, setFlagMessage] = useState(false);


    useEffect(() => {
        if (user.id === undefined) {
            dispatch(fetchUser())
        }
        setFlagMessage(false);

        let flag = false;

        if (modal.type === 'MODAL_ERROR' && modal.show === true) {
            modal.show = false;
            // dispatch(closeModalError());
            setError(true);
            flag = true;
            setFlagSave(false);
        }

        if (!error && !flag && flagSave) {
            setFlagMessage(true);
        }

    }, [user, modal])

    function navigate() {
        nav("/");
    }

    const save = (e) => {

        setNewPassword("");
        setNewPasswordRepeat("");
        setPassword("");
        if (newPassword !== newPasswordRepeat || (newPassword.length < 1)) {
            setError(true)
            return
        }

        let updatePassword = {};
        updatePassword.id = user.id;
        updatePassword.oldPassword = password;
        updatePassword.newPassword = newPassword;

        dispatch(fetchUserUpdatePassword(updatePassword));
        setError(false);
        setFlagSave(true)

    }


    return (
        <ProfileContainer>
            <ProfileLinkContainer>
                <ArrowBack func={navigate}/>
                {/*<Link*/}
                {/*    name={"Назад"}*/}
                {/*    onClick={navigate}*/}
                {/*/>*/}
            </ProfileLinkContainer>

            <H1>Изменение пароля</H1>
            <ProfileBody>
                <ProfileForm>
                    <P>Старый пароль</P>
                    <Input
                        type={"password"}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        error={error}
                        disabled={false}
                    />
                    <P>Новый пароль</P>
                    <Input
                        type={"password"}
                        onChange={e => setNewPassword(e.target.value)}
                        value={newPassword}
                        error={error}
                        disabled={false}
                    />
                    <P>Повторите новый пароль</P>
                    <Input
                        type={"password"}
                        onChange={e => setNewPasswordRepeat(e.target.value)}
                        value={newPasswordRepeat}
                        error={error}
                        disabled={false}
                    />
                </ProfileForm>
            </ProfileBody>
            <ProfileButtonContainer>
                <Button onClick={save}>Сохранить</Button>
            </ProfileButtonContainer>
            {flagMessage && <Psuccess>Сохранено успешно</Psuccess>}
            {error && <Perror>Неверный пароль</Perror>}
        </ProfileContainer>
    )
}

export default PasswordPage;
