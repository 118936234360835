import {ToolBarActionContainer, Container} from "../../common/CommonStyle";
import styled from "styled-components";
import Checkbox from "../components/Checkbox";
import Editor from "../components/Editor";
import Basket from "../components/Bascket";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deletePurchase, editPurchase} from "../../store/actions/api/actionApiPurchase";
import {showModalChooseGroup, showModalPurchase} from "../../store/actions/actionModal";
import {store} from "../../store/Store";
import {isMobile, pressKeyEnter} from "../../common/Const";
import {PurchaseCardColor} from "../../common/Color";
import moment from "moment/moment";
import {getTimeZoneHourAndMinute} from "../../common/TimeZone";


const PurchaseCardContainer = styled.div`
  //position: relative;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${PurchaseCardColor.backgroundColor};
  ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.backgroundColorSelf + ";" : ''};
  border-radius: 10px;
  transition: background-color .4s;
  color: ${PurchaseCardColor.colorText};

  box-shadow: ${props => props.isSelfCard === true ? PurchaseCardColor.boxShadowSelf : PurchaseCardColor.boxShadow};

  @media (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &:hover {
    background-color: ${PurchaseCardColor.hover};
    ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.hoverSelf + ";" : ''};
    ${isMobile === true ? (props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.backgroundColorSelf + ";" : 'background-color: ' + PurchaseCardColor.backgroundColor + ";") : ''};
  }

  &:active {
    background-color: ${PurchaseCardColor.active};
    ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.activeSelf + ";" : ''}
  }

  &:focus-visible {
    background-color: ${PurchaseCardColor.focusVisible};;
    outline: 0;
    ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.focusVisibleSelf + ";" : ''};

  }

`
const PurchaseCardToolBar = styled(ToolBarActionContainer)`
  margin-left: auto;
`

const PurchaseCardDate = styled.div`
  justify-self: start;
  color: gray;
  display: flex;
  margin-left: 20px;
  align-items: center;
  font-size: 12px;
  @media (max-width: 480px) {
    margin-left: 30px;
  }
  svg {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
  path {
    fill: gray;
    transition: stroke .4s;
  }
  @media (max-width: 760px) {
    grid-column: 1;
  }
`

const PurchaseCard = ({card, setContext}) => {
    const dispatch = useDispatch();
    const {user} = useSelector(() => store.getState())
    const purchaseMenuRef = useRef(null);

    const [mTimer, setMTimer] = useState(null)

    let timer;
    let context = {}

    useEffect(() => {
    }, [card.checked])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
    }, [])

    const handleOutsideClick = (event) => {
        if (event && event.target !== null && purchaseMenuRef && purchaseMenuRef.current !== null && !purchaseMenuRef.current.contains(event.target)) {
            setTimeout(() => {
                    closeContextMenu();
            }, 1)
        }
    }

    const closeContextMenu = () => {
        context.show = false
        context.locationY = localStorage.getItem("locationY")
        context.locationX = localStorage.getItem("locationX")
        context.dat = card
        setContext(context)
    }

    const handleCheck = (e) => {
        let purchase = {};
        purchase.id = card.id;
        purchase.groupId = card.groupId;
        purchase.name = card.name;
        purchase.userId = card.userId;
        purchase.checked = !card.checked
        dispatch(editPurchase(purchase))
    }

    function delPurchase(e, id) {
        //    e.stopPropagation();
        dispatch(deletePurchase(id));
    }

    function handleEdit(e, card) {
        //   e.stopPropagation();
        dispatch(showModalPurchase(card))
    }


    function touchStart(e) {
        timer = setTimeout(() => {
            context.show = true;

            if (window.innerWidth - e.touches[0].pageX > 140) {
                context.locationX = (e.touches[0].pageX);
            } else {
                context.locationX = (e.touches[0].pageX - 140);
            }

            if (window.innerHeight - e.touches[0].pageY > 90) {
                context.locationY = (e.touches[0].pageY);
            } else {
                context.locationY = (e.touches[0].pageY - 90)
            }

            localStorage.setItem("locationX", context.locationX)
            localStorage.setItem("locationY", context.locationY)
            context.dat = card
            context.fEdit = (() => dispatch(showModalPurchase(card)))
            context.fDel = (() => dispatch(deletePurchase(card.id)))
            context.fMove = (() => dispatch(showModalChooseGroup(card.groupId, [].concat(card.id))))
            setContext(context)
        }, 1000)
        setMTimer(timer)
    }

    function touchEnd(e) {
       //e.stopPropagation();
        clearTimeout(mTimer);
    }

    function mouseUp(e) {
        if (!isMobile) {
            touchEnd(e)
        }
    }

    function touchMove(e) {
        e.stopPropagation();
        clearTimeout(mTimer);
    }

    if (user.id == null || user.id === undefined) {
        return null;
    }

    const getDate = (card) => {
        let timeZoneObj = getTimeZoneHourAndMinute(user.timeZone);
        let momentX = moment([card.date[0], card.date[1] - 1, card.date[2], card.date[3], card.date[4]]).add(timeZoneObj.hour, 'hour').add(timeZoneObj.minute, 'minute');
        return moment(momentX).format("DD.MM.YYYY HH:mm");
    }

    return (
        <>
            <PurchaseCardContainer ref={purchaseMenuRef}
                                   isSelfCard={user.id != card.userId}
                                   tabindex={0}
                                   onKeyPress={e => pressKeyEnter(e, () => mouseUp(e))}
                                   onTouchStart={touchStart}
                                   onTouchEnd={touchEnd}
                                   onTouchMove={touchMove}
                                   onClick={mouseUp}
            >
                <Checkbox click={handleCheck} text={card.name} checked={card.checked} date={getDate(card)}/>
                <PurchaseCardToolBar>
                    <Editor click={e => handleEdit(e, card)}/>
                    <Basket click={e => delPurchase(e, card.id)}/>
                </PurchaseCardToolBar>
                <PurchaseCardDate>
                    <svg aria-hidden="true" className="bk-icon -experiments-calendar sb-date-picker_icon-svg"
                         fill="#FFFFFF" focusable="false" height="15" role="presentation" width="15"
                         viewBox="0 0 128 128">
                        <path
                            d="m112 16h-16v-8h-8v8h-48v-8h-8v8h-16c-4.4 0-8 3.9-8 8.7v86.6c0 4.8 3.6 8.7 8 8.7h96c4.4 0 8-3.9 8-8.7v-86.6c0-4.8-3.6-8.7-8-8.7zm0 95.3a1.1 1.1 0 0 1 -.2.7h-95.6a1.1 1.1 0 0 1 -.2-.7v-71.3h96zm-68-43.3h-12v-12h12zm0 28h-12v-12h12zm26-28h-12v-12h12zm0 28h-12v-12h12zm26 0h-12v-12h12zm0-28h-12v-12h12z"
                            fill-rule="evenodd"></path>
                    </svg>
                    {getDate(card)}
                </PurchaseCardDate>
            </PurchaseCardContainer>
        </>
    )
}
export default PurchaseCard

