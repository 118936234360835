import {Settings} from "../../../settings";

export const fetchPurchase = (groupId) => ({
    type: 'FETCH_PURCHASE_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/list/' + groupId,
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});


export const fetchPurchaseById = (id) => ({
    type: 'FETCH_PURCHASE_BY_ID_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/' + id,
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});

export const editPurchase = (purchase) => ({
    type: 'FETCH_EDIT_PURCHASE_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/',
        init: {
            method: 'PUT',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify (purchase)
        }
    }
});

export const addPurchase = (purchase) => ({
    type: 'FETCH_ADD_PURCHASE_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/',
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify (purchase)
        }
    }
});


export const deletePurchase = (id) => ({
    type: 'FETCH_DELETE_PURCHASE_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/' + id,
        init: {
            method: 'DELETE',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    },
    id: id
})

export const deleteAllCheckedPurchase = (id) => ({
    type: 'FETCH_DELETE_ALL_CHECKED_PURCHASE_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/checked/' + id,
        init: {
            method: 'DELETE',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
})

export const checkAllPurchaseInGroup = (id) => ({
    type: 'FETCH_CHECK_ALL_PURCHASE_IN_GROUP_REQUEST',
    payload: {
        url: Settings.url + '/api/purchase/checkedAll/' + id,
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    },
    id: id
})

export const fetchMovePurchases = (oldGroupId, newGroupId, purchaseIds) => ({
    type: 'FETCH_MOVE_PURCHASES',
    payload: {
        url: Settings.url + '/api/purchase/move/' + newGroupId,
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify (purchaseIds)
        }
    },
    id: oldGroupId
});
