export default function reducerModalWarning(state = [], action) {
    switch (action.type) {
        case "SHOW_MODAL_WARNING":
            return {show: true, type: "MODAL_WARNING", action: action.action, text: action.text};

        case "CLOSE_MODAL_WARNING":
            return {show: false, type: "MODAL_WARNING", text: action.text};

        default: return state;
    }
}
