import styled from "styled-components";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import Checkbox from "../main/components/Checkbox";
import {PurchaseCardColor} from "../common/Color";
import {isMobile} from "../common/Const";
import {Settings} from "../settings";
import {closeLoading, showLoading} from "../store/actions/actionLoading";
import {getTimeZoneHourAndMinute} from "../common/TimeZone";
import moment from "moment";
import {editPurchaseAction} from "../store/actions/actionPurchace";


const PurchaseCardContainer = styled.div`
  //position: relative;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background-color: ${PurchaseCardColor.backgroundColor};
  ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.backgroundColorSelf + ";" : ''};
  border-radius: 10px;
  transition: background-color .4s;
  color: ${PurchaseCardColor.colorText};

  box-shadow: ${props => props.isSelfCard === true ? PurchaseCardColor.boxShadowSelf : PurchaseCardColor.boxShadow};

  @media (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &:hover {
    background-color: ${PurchaseCardColor.hover};
    ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.hoverSelf + ";" : ''};
    ${isMobile === true ? (props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.backgroundColorSelf + ";" : 'background-color: ' + PurchaseCardColor.backgroundColor + ";") : ''};
  }

  &:active {
    background-color: ${PurchaseCardColor.active};
    ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.activeSelf + ";" : ''}
  }

  &:focus-visible {
    background-color: ${PurchaseCardColor.focusVisible};;
    outline: 0;
    ${props => props.isSelfCard === true ? 'background-color: ' + PurchaseCardColor.focusVisibleSelf + ";" : ''};

  }
     label {
      div {
        p {
          -webkit-user-select: text;
        }
        svg {
          top: 50%;
        }
      }
    }
    
  

`

const PurchaseCardDate = styled.div`
  justify-self: start;
  color: gray;
  display: flex;
  margin-left: 20px;
  align-items: center;
  font-size: 12px;
  @media (max-width: 480px) {
    margin-left: 30px;
  }
  svg {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
  path {
    fill: gray;
    transition: stroke .4s;
  }
  @media (max-width: 760px) {
    grid-column: 1;
  }
`

const PublicPurchaseCard = ({card, timeZone}) => {
    const dispatch = useDispatch();

    useEffect(() => {
    }, [card.checked])

    const handleCheck = (e) => {
        let purchase = {};
        purchase.id = card.id;
        purchase.groupId = card.groupId;
        purchase.name = card.name;
        purchase.userId = card.userId;
        purchase.checked = !card.checked
        purchase.date = card.date
        console.log(Settings.url + '/api/public/purchase/' + card.id + '?checked=' + purchase.checked)
        try {
            dispatch(showLoading());
            fetch(Settings.url + '/api/public/purchase/' + card.id + '?checked=' + purchase.checked,
                {
                    method: 'PUT',
                    signal: AbortSignal.timeout(500),
                    headers: {'Content-type': 'application/json'},
                }).then((response) => {
                dispatch(closeLoading());
                if (response.status === 204) {
                    dispatch(editPurchaseAction(purchase))
                   // response.json().then(result => setData({error: false, body: result}));
                }
            })
        } catch (e) {
            dispatch(closeLoading());
        }

    }

    const getDate = (card) => {
        let timeZoneObj = getTimeZoneHourAndMinute(timeZone);
        let momentX = moment([card.date[0], card.date[1] - 1, card.date[2], card.date[3], card.date[4]]).add(timeZoneObj.hour, 'hour').add(timeZoneObj.minute, 'minute');
        return moment(momentX).format("DD.MM.YYYY HH:mm");
    }

    return (
        <>
            <PurchaseCardContainer tabindex={0}>
                <Checkbox click={handleCheck} text={card.name} checked={card.checked}/>
                <PurchaseCardDate>
                    <svg aria-hidden="true" className="bk-icon -experiments-calendar sb-date-picker_icon-svg"
                         fill="#FFFFFF" focusable="false" height="15" role="presentation" width="15"
                         viewBox="0 0 128 128">
                        <path
                            d="m112 16h-16v-8h-8v8h-48v-8h-8v8h-16c-4.4 0-8 3.9-8 8.7v86.6c0 4.8 3.6 8.7 8 8.7h96c4.4 0 8-3.9 8-8.7v-86.6c0-4.8-3.6-8.7-8-8.7zm0 95.3a1.1 1.1 0 0 1 -.2.7h-95.6a1.1 1.1 0 0 1 -.2-.7v-71.3h96zm-68-43.3h-12v-12h12zm0 28h-12v-12h12zm26-28h-12v-12h12zm0 28h-12v-12h12zm26 0h-12v-12h12zm0-28h-12v-12h12z"
                            fill-rule="evenodd"></path>
                    </svg>
                    {getDate(card)}
                </PurchaseCardDate>
            </PurchaseCardContainer>
        </>
    )
}
export default PublicPurchaseCard

