import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {closeModalError} from "../store/actions/actionModal";
import {store} from "../store/Store";
import {Modal} from "../common/CommonStyle";
import {Button} from "../common/style/Style";
import {pressKeyEnter, pressKeyEsc} from "../common/Const";
import {ModalContent, ModalContentContainer, ModalText} from "./ModalStyle";


const ModalContentError = styled(ModalContent)`
  padding: 0;
  margin: 0 10px;
`

const ModalHeader = styled(ModalContent)`
  padding: 10px;
  width: 100%;
  max-width: none;
  background-color: red;
  border-radius: 10px 10px 0px 0px;
`

const ModalContentContainerError = styled(ModalContentContainer)`
  padding: 10px;
`
const ModalButton = styled(Button)`
  width: 70px;
`


const ModalWindowError = () => {
    const dispatch = useDispatch();
    const {modal} = useSelector(() => store.getState())


    if (Array.isArray(modal)) {
        return;
    }
    return (
        <Modal show={modal.show && modal.type === "MODAL_ERROR"}
               onKeyDown={e => pressKeyEsc(e, () => dispatch(closeModalError()))}
        >
            <ModalContentError>
                <ModalHeader>Ошибка!</ModalHeader>
                <ModalContentContainerError>
                    <ModalText>{modal.data.toString()}</ModalText>
                    <ModalButton autofocus onClick={() => dispatch(closeModalError())}>OK</ModalButton>
                </ModalContentContainerError>
            </ModalContentError>
        </Modal>
    )
}

export default ModalWindowError;



