import {Settings} from "../../../settings";

export const fetchToken = (credential) => ({
    type: 'FETCH_TOKEN_REQUEST',
    payload: {
        url: Settings.url + '/api/auth/token',
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {
                'Content-type': 'application/x-www-form-urlencoded'
            },
            body: 'grant_type=' + credential.type +
                '&username=' + credential.username +
                '&password=' + credential.password +
                '&token=' + credential.token,
        }
    }
});


export const fetchRegistration = (body) => ({
    type: 'FETCH_REGISTRATION_REQUEST',
    payload: {
        url: Settings.url + '/api/auth/registration',
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {
                'Content-type': 'application/x-www-form-urlencoded'
            },
            body: 'name=' + body.name +
                '&username=' + body.username +
                '&password=' + body.password +
                '&email=' + body.email,
        }
    }
});

