
export default function reducerUserListInGroup(state = [], action) {
    switch (action.type) {
        case "FIND_USER_LIST_IN_GROUP": {
            return action.payload;
        }

        case "ADD_USER_IN_USER_LIST_IN_GROUP": {
            return [...state, action.payload];
        }

        case "DELETE_USER_IN_USER_LIST_IN_GROUP": {
            return state.filter(user => user.id !== action.payload.id);
        }
        default:
            return state;
    }
}
