import styled from "styled-components";
import {HeaderMenuColor} from "../Color";
import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {showModalWarning} from "../../store/actions/actionModalWarning";


const Menu = styled.div`
  position: absolute;
  transition: opacity .4s, visibility .4s, max-height .4s;
  ${props => props.show === true ? "visibility: visible; max-height: 100px;" : "visibility: hidden; max-height: 0;"};
  background-color: ${HeaderMenuColor.backgroundColor};
  box-shadow: 0px 0px 9px -1px rgba(34, 60, 80, 0.6);
  border-radius: 5px;
  top: ${props => props.locationY}px;
  left: ${props => props.locationX}px;
  right: 0;
  max-width: max-content;
  overflow: hidden;
  z-index: 9000;

  ul {
    margin: 0;
    padding: 3px 5px;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`

const MenuItem = styled.li`
  margin: 0;
  padding: 3px 5px;
  display: flex;
  list-style-type: none;
  transition: background-color .4s;
  @media (max-width: 480px) {
    font-size: 18px;
  }
  p {
    color: ${HeaderMenuColor.colorText};
  }
  &:active {
    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
    background-color: ${HeaderMenuColor.hover};
  }
  
  &:focus-visible {
    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
    background-color:  ${HeaderMenuColor.focusVisible};;
    outline: 0;
  }
`

const ContextMenu = ({context, setContext}) => {
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    let contextMenu = {}

    useEffect(() => {
        document.addEventListener('touchstart', handleOutsideClick);
    }, [context])

    const handleOutsideClick = (event) => {
        if (typeof setContext === 'function' && event && event.target !== null && menuRef && menuRef.current !== null && !menuRef.current.contains(event.target)) {
            setTimeout(() => {
                contextMenu.show = false
                contextMenu.locationY = localStorage.getItem("locationY")
                contextMenu.locationX = localStorage.getItem("locationX")
                setContext(contextMenu)
            }, 1)
        }
    }


    const del = () => {
        context.fDel(); localStorage.setItem("locationY", "-100000");
    }

    return (
        <Menu ref={menuRef} show={context.show} locationX={context.locationX} locationY={context.locationY}>
        <ul>
            <MenuItem onClick={() => {context.fEdit(); localStorage.setItem("locationY", "-100000");}}>Редактировать</MenuItem>
            <MenuItem onClick={() => {dispatch(showModalWarning(del, 'Удалить ' + context.dat.name + '?' ))}}>Удалить</MenuItem>
            {context.fMove !== undefined && <MenuItem onClick={() => {context.fMove(); localStorage.setItem("locationY", "-100000");}}>Переместить</MenuItem> }
        </ul>
    </Menu>
    )

}
export default ContextMenu
