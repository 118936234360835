import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container, TitleStyle} from "../common/CommonStyle";
import GroupCard from "./card/GroupCard";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../store/Store";
import {fetchGroup} from "../store/actions/api/actionApiGroup";
import {showModalAddGroup} from "../store/actions/actionModal";
import {Button} from "../common/style/Style";
import {isMobile} from "../common/Const";
import {MainColor} from "../common/Color";
import ContextMenu from "../common/contextMenu/ContextMenu";
import {ContextMenuClass} from "../common/contextMenu/ContextMenuClass";


const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
`
const MainTitle = styled(TitleStyle)`
`

const MainButton = styled(Button)`
  ${MainColor.colorText};
  background-color:  ${MainColor.backgroundColor};
  font-size: 14px;
  font-weight: 600;
  color: ${MainColor.colorText};
  margin-bottom: 10px;
  box-shadow: ${MainColor.boxShadow};
  max-width: max-content;

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &:hover {
    background-color: ${MainColor.hover};
    ${isMobile === true ? "background-color: " +  MainColor.backgroundColor + ";" : ""}
  }

  &:active {
    background-color: ${MainColor.active};
  }

  &:focus-visible {
    background-color: ${MainColor.focusVisible};
  }
`

const MainGroupList = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;
  overflow: auto;
  li {
    margin: 0;
    padding: 0 5px 0 0px;
    list-style-type: none;
    &:last-child {
      margin-bottom: 2px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
  }
`

const Groups = ({groups, user, self, setContext}) => {
    if (groups.length > 0) {
        return groups.filter(gr => self === true ? gr.userId == user.id : gr.userId != user.id ).map(group => {
            return (
               <li>
                    <GroupCard key={group.id} group={group} setContext={setContext}/>
               </li>
            )
        })
    }
    return null;
};

const Main = () => {
    const dispatch = useDispatch();
    const groups = useSelector(() => store.getState()).groups;
    const user = useSelector(() => store.getState()).user;
    const [context, setContext] = useState(ContextMenuClass);
    useEffect(() => {
        dispatch(fetchGroup());
    }, [])

    return (
        <main>
            <MainContainer>
                <h1 hidden={true}>Purchase list</h1>
                <MainTitle>Группы</MainTitle>
                <MainButton onClick={() => dispatch(showModalAddGroup())}>+ Добавить группу</MainButton>
                <MainGroupList>
                    <Groups groups={groups} user={user} self={true} setContext={setContext}/>
                    <Groups groups={groups} user={user} self={false} setContext={setContext}/>
                </MainGroupList>
                <ContextMenu key={'menu'} context={context} setContext={setContext} />
            </MainContainer>
        </main>
    )
}

export default Main
