import styled from "styled-components";


export const Input = styled.input`
  font-size: 12px;
  padding: 5px 10px;
  // border-radius: 10px;
  cursor: pointer;
  //outline: 1px solid #a2a1a1;
  border-radius: 10px;
  ${props => props.error === true ? "background-color: #fab7b7;" : ""}

  transition: background-color .4s, outline .4s, border .4s;
  border: 1px solid black;
  outline: 2px solid transparent;
  @media (max-width: 480px) {
    font-size: 18px;
  }

  &:hover {
    background-color: #dfdffc;
  }

  &:disabled {
    background-color: transparent;
  }

  &:focus-visible {
    border: 1px solid transparent;
    outline: 2px solid blue;
    background-color: inherit;
  }
`
export const TextArea = styled.textarea`
  font-size: 12px;
  resize: none;
  padding: 5px 10px;
  // border-radius: 10px;
  cursor: pointer;
  //outline: 1px solid #a2a1a1;
  border-radius: 10px;
  ${props => props.error === true ? "background-color: #fab7b7;" : ""}

  transition: background-color .4s, outline .4s, border .4s;
  border: 1px solid black;
  outline: 2px solid transparent;

  &:hover {
    background-color: #dfdffc;
  }

  &:disabled {
    background-color: transparent;
  }

  &:focus-visible {
    border: 1px solid transparent;
    outline: 2px solid blue;
    background-color: inherit;
  }
`

export const Button = styled.button`

  background-color: #6666f3;

  color: white;
  padding: 5px 10px;
  border: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: background-color .4s, outline .4s;
  border-radius: 10px;
  font-size: 20px;
  @media (max-width: 480px) {
    font-size: 20px;
  }

  ${props => props.disabled === true ? "outline: 0; " : ""}
  &:hover {
    background-color: blue;
  }

  &:active {
    background-color: #030393;
  }

  &:focus-visible {
    outline-color: blue;
  }

  &:disabled {
    background-color: #acacf1;
  }
`

export const P = styled.p`
  margin: 0;
  padding: 0;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
`
export const H2 = styled.h2`
  margin: 0;
  padding: 0;
`

export const H3 = styled.h3`
  margin: 0;
  padding: 0;
`

export const A = styled.a`
  margin: 0;
  padding: 0;
  color: blue;
  
  outline: 2px solid transparent;
  outline-offset: 2px;
  
  transition: outline .4s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    color: #5959ef;
  }

  &:focus-visible {
    color: blue;
    outline: 2px solid blue;
  }
`

export const Select = styled.select`
  padding: 3px 5px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: white;
  border: 1px solid black;
  color: black;
  font-size: 12px;
  &:focus-visible {
    border: 1px solid transparent;
    padding: 3px 5px;
    outline: 2px solid blue;
    background-color: white;
  }
  @media (max-width: 480px) {
    font-size: 18px;
    max-width: 280px;
  }
`
