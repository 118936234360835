import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Outlet, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../store/Store";
import {fetchUser} from "../store/actions/api/actionApiUser";
import { P} from "../common/style/Style";
import exit from "../img/4115235-exit-logout-sign-out_114030.svg"
import lock from "../img/lock-outlined-padlock-symbol-for-security-interface_icon-icons.com_57803.svg"
import userImg from  "../img/User.svg";

import {isMobile, pressKeyEnter} from "../common/Const";
import {HeaderColor, HeaderMenuColor} from "../common/Color";

const HeaderContainer = styled.div`
  padding: 10px 20px;
  background-color: ${HeaderColor.backgroundColor};
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  position: relative;

  //@media (max-width: 680px) {
  //  padding: 5px 10px;
  //}
`

const HeaderText = styled(P)`
    color: ${HeaderColor.colorText};
    @media (max-width: 480px) {
      font-size: 20px;
      white-space: nowrap;
    }
`

const HeaderBody = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const HeaderArrow = styled(P)`
  transition: transform .4s;
  ${props => props.show === true ? "transform: rotate(180deg);" : ""}
  font-size: 12px;
  align-self: center;
  color: ${HeaderColor.colorText};
  @media (max-width: 480px) {
    font-size: 15px;
  }
`

const HeaderProfile = styled.div`
  position: relative;
  display: flex;
`

const HeaderLogo = styled.div`
  p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: ${HeaderColor.colorText};
    @media (max-width: 480px) {
      font-size: 25px;
    }
  }
`

const HeaderCallMenuContainer = styled.div`
  display: flex;
  gap: 5px;
  border-radius: 10px;
  padding: 5px 10px;

  &:hover {
    box-shadow: ${isMobile == true ? 'inherit' : '0px 5px 10px 3px rgba(34, 60, 80, 0.2)'};
  }

  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: outline .4s;

  &:focus-visible {
    outline:  2px solid  ${HeaderColor.focusVisible};
  }
  
`

const HeaderImg = styled.img`
  border-radius: 100%;
  width: 30px;
  height: 30px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: outline .4s;
  object-fit: cover;

  @media (max-width: 480px) {
    width: 35px;
    height: 35px;
  }
  
  &:focus-visible {
    outline:  1px solid ${HeaderColor.focusVisible};
  }
`

const HeaderFigure = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transition: visibility .4s, transform .4s;
  ${props => props.show === true ? "visibility: visible; transform: scale(1); transform: scale(1);" : "visibility: hidden; transform: scale(0);"};
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  z-index: 9000;
  img {
    object-fit: contain;
  }
`

const HeaderFigureClose = styled.div`
  position: absolute;
  display: flex;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 20px;

  transition: color .4s, background-color .4s;

  p {
    opacity: 0.7;
    transition: opacity .4s;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    p {
      opacity: 1;
      color: black;
    }
  }
`
const Conv = styled.div`
  height: 100vh;
 // overflow: hidden;
`

const Header = () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const {user} = useSelector(() => store.getState());

    const [show, setShow] = useState(false);
    const [figure, setFigure] = useState(false);
    const menuRef = useRef(null);

    function exit(e) {
        localStorage.clear();
        nav("/auth");
        setShow(false);
    }

    function handleProfile(e) {
        nav("/profile");
        setShow(false);
    }

    function handlePassword(e) {
        nav("/password");
        setShow(false);
    }

    useEffect(() => {
        dispatch(fetchUser());
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    }, [])

    const handleOutsideClick = (event) => {
        if (event && event.target !== null && menuRef && menuRef.current !== null && !menuRef.current.contains(event.target)) {
            setTimeout(() => {
                setShow(false)
            }, 1)
        }
    }

    const showImg = (e) => {
        setFigure(true)
        document.addEventListener('keypress', handleAnyPressKey);
    }

    const handleAnyPressKey = (e) => {
        document.removeEventListener('keypress', handleAnyPressKey);
        setFigure(false)
    }



    return (
        <Conv>
            <header>
                <HeaderContainer>
                    <HeaderBody>
                        <HeaderLogo onClick={() => window.location.replace("/")}>
                            <p>СПИСОК ©</p>
                        </HeaderLogo>
                        <HeaderProfile ref={menuRef}>
                            <HeaderImg
                                tabindex={0}
                                src={user.img != null ? user.img : userImg}
                                alt={"Автар"}
                                onClick={showImg}
                                onKeyPress={e => pressKeyEnter(e, showImg)}
                            />
                            { user.name && <HeaderCallMenuContainer
                                tabindex={0}
                                onKeyPress={e => pressKeyEnter(e, () => setShow(!show))}
                                onClick={() => setShow(!show)}>
                                <HeaderText>{user.name}</HeaderText>
                                <HeaderArrow show={show}>v</HeaderArrow>
                            </HeaderCallMenuContainer> }
                            <Menu show={show} handleProfile={handleProfile} handleExit={exit} handlePassword={handlePassword}/>
                        </HeaderProfile>
                    </HeaderBody>
                    <HeaderFigure
                        show={figure}>
                        <HeaderFigureClose
                            tabindex={0}
                            onKeyPress={e => pressKeyEnter(e, () => setFigure(false))}
                            onClick={() => setFigure(false)}>
                            <P>X</P>
                        </HeaderFigureClose>
                        <img src={user.img != null ? user.img: userImg} alt={"ava"}/>
                    </HeaderFigure>
                </HeaderContainer>
            </header>
            <Outlet/>
        </Conv>
    )
}

export default Header


const HeaderMenu = styled.div`
  position: absolute;
  transition: opacity .4s, visibility .4s, max-height .4s;
  ${props => props.show === true ? "visibility: visible; max-height: 100px;" : "visibility: hidden; max-height: 0;"};
  transform: translate(18px, 10px);
  background-color: ${HeaderMenuColor.backgroundColor};
 // outline: 1px solid black;
  box-shadow: 0px 0px 9px -1px rgba(34, 60, 80, 0.6);
  border-radius: 5px;
  top: 100%;
  right: 10px;
  //width: 150px;
  overflow: hidden;
  width: max-content;
  z-index: 9000;

  ul {
    margin: 0;
    padding: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`

const HeaderMenuItem = styled.li`
  margin: 0;
  padding: 3px;
  display: flex;
  list-style-type: none;
  transition: background-color .4s;
  p {
    color: ${HeaderMenuColor.colorText};
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
    background-color: ${HeaderMenuColor.hover};
  }
  
  &:focus-visible {
    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
    background-color:  ${HeaderMenuColor.focusVisible};;
    outline: 0;
  }

  svg {
    width: 15px;
    margin-right: 5px;
  }

  img {
    width: 15px;
    margin-right: 5px;
  }
`

const Menu = ({show, handleProfile, handleExit, handlePassword}) => {
    return (
        <HeaderMenu show={show}>
            <ul>
                <HeaderMenuItem
                    tabindex={0}
                    onKeyPress={e => pressKeyEnter(e, handleProfile)}
                    onClick={handleProfile}>
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/>
                        <g id="about">
                            <path d="M16,16A7,7,0,1,0,9,9,7,7,0,0,0,16,16ZM16,4a5,5,0,1,1-5,5A5,5,0,0,1,16,4Z"/>
                            <path
                                d="M17,18H15A11,11,0,0,0,4,29a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1A11,11,0,0,0,17,18ZM6.06,28A9,9,0,0,1,15,20h2a9,9,0,0,1,8.94,8Z"/>
                        </g>
                    </svg>
                    <HeaderText>Профиль</HeaderText>
                </HeaderMenuItem>
                <HeaderMenuItem
                    tabindex={0}
                    onKeyPress={e => pressKeyEnter(e, handlePassword)}
                    onClick={handlePassword}>
                    <img src={lock} alt={"Lock"}/>
                    <HeaderText>Смена пароля</HeaderText>
                </HeaderMenuItem>
                <HeaderMenuItem
                    tabindex={0}
                    onKeyPress={e => pressKeyEnter(e, handleExit)}
                    onClick={handleExit}>
                    <img src={exit} alt={"Exit"}/>
                    <HeaderText>Выход</HeaderText>
                </HeaderMenuItem>

            </ul>
        </HeaderMenu>
    )
}
