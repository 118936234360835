import {fetchRequest, fetchRequestRegistration} from "../../request/Rest";
import {
    incCountUserInGroupAction,
    addGroupAction,
    deleteGroupByIdAction,
    editGroupAction,
    fetchGroupAction, decrCountUserInGroupAction
} from "../actions/actionGroup";
import {
    addPurchaseAction,
    deleteCheckedPurchase,
    deletePurchaseByIdAction,
    editPurchaseAction,
    findPurchaseAction
} from "../actions/actionPurchace";
import {findUserAction, findUserListByHalfNameAction} from "../actions/actionUser";
import {findUserListInGroupAction} from "../actions/actionUserListGroup";
import {fetchPurchase} from "../actions/api/actionApiPurchase";

export default function reducerApi(state = [], action) {
    switch (action.type) {
        case "FETCH_GROUP_BY_ID_REQUEST":
        case "FETCH_GROUP_REQUEST": {
            fetchRequest(action.payload, fetchGroupAction)
            return action;
        }
        case "FETCH_DELETE_GROUP_REQUEST": {
            fetchRequest(action.payload, deleteGroupByIdAction(action.id));
            return action;
        }
        case "FETCH_ADD_GROUP_REQUEST": {
            fetchRequest(action.payload, addGroupAction);
            return action;
        }
        case "FETCH_EDIT_GROUP_REQUEST": {
            fetchRequest(action.payload, editGroupAction);
            return action;
        }

        case "FETCH_PURCHASE__BY_ID_REQUEST":
        case "FETCH_PURCHASE_REQUEST": {
            fetchRequest(action.payload, findPurchaseAction)
            return action;
        }

        case "FETCH_DELETE_PURCHASE_REQUEST":
        {
            fetchRequest(action.payload, deletePurchaseByIdAction(action.id));
            return action;
        }
        case "FETCH_DELETE_ALL_CHECKED_PURCHASE_REQUEST":
        {
            fetchRequest(action.payload, deleteCheckedPurchase());
            return action;
        }
        case "FETCH_CHECK_ALL_PURCHASE_IN_GROUP_REQUEST":
        {
            fetchRequest(action.payload, fetchPurchase(action.id));
            return action;
        }

        case "FETCH_ADD_PURCHASE_REQUEST":
        {
            fetchRequest(action.payload, addPurchaseAction);
            return action;
        }

        case "FETCH_MOVE_PURCHASES":
        {
            fetchRequest(action.payload, fetchPurchase(action.id));
            return action;
        }


        case "FETCH_EDIT_PURCHASE_REQUEST": {
            fetchRequest(action.payload, editPurchaseAction)
            return action;
        }

        case "FETCH_USER_REQUEST": {
            fetchRequest(action.payload, findUserAction)
            return action;
        }

        case "FETCH_USER_UPDATE_PASSWORD_REQUEST":
        case "FETCH_USER_UPDATE_REQUEST": {
            fetchRequest(action.payload, findUserAction)
            return action;
        }

        case "FETCH_USER_BY_HALF_NAME_REQUEST": {
            fetchRequest(action.payload, findUserListByHalfNameAction)
            return action;
        }

        case "FETCH_INVITE_USER_REQUEST": {
            fetchRequest(action.payload, incCountUserInGroupAction)
            return action;
        }

        case "FETCH_USER_UPDATE_AVATAR_REQUEST": {
            fetchRequest(action.payload, findUserAction)
            return action;
        }

        case "FETCH_DELETE_INVITE_USER_REQUEST": {
            fetchRequest(action.payload, decrCountUserInGroupAction)
            return action;
        }

        case "FETCH_FIND_USER_IN_GROUP_BY_ID_REQUEST": {
            fetchRequest(action.payload, findUserListInGroupAction)
            return action;
        }

        case "FETCH_REGISTRATION_REQUEST": {
            fetchRequestRegistration(action.payload)
            return action;
        }
        case "FETCH_TOKEN_REQUEST": {
            fetchRequestRegistration(action.payload);
            return action;
        }

        default:
            return state;
    }
}
