import {Settings} from "../../../settings";

export const fetchUser = () => ({
    type: 'FETCH_USER_REQUEST',
    payload: {
        url: Settings.url + '/api/user/name',
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});

export const fetchUserUpdate = (user) => ({
    type: 'FETCH_USER_UPDATE_REQUEST',
    payload: {
        url: Settings.url + '/api/user/',
        init: {
            method: 'PUT',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(user)
        }
    }
});

export const fetchUserUpdatePassword = (user) => ({
    type: 'FETCH_USER_UPDATE_PASSWORD_REQUEST',
    payload: {
        url: Settings.url + '/api/user/password',
        init: {
            method: 'PUT',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(user)
        }
    }
});


export const fetchUserByHalfName = (name, groupId) => ({
    type: 'FETCH_USER_BY_HALF_NAME_REQUEST',
    payload: {
        url: Settings.url + '/api/user/search?name=' + name + '&groupId=' + groupId,
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});

export const fetchFindUserInGroupById = (groupId) => ({
    type: 'FETCH_FIND_USER_IN_GROUP_BY_ID_REQUEST',
    payload: {
        url: Settings.url + '/api/user/group/' + groupId,
        init: {
            method: 'GET',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'}
        }
    }
});

export const fetchInviteUser = (userId, groupId) => ({
    type: 'FETCH_INVITE_USER_REQUEST',
    payload: {
        url: Settings.url + '/api/user/invite',
        init: {
            method: 'POST',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                user_id: userId,
                group_id: groupId
            })
        }
    }
});

export const fetchUserUpdateAvatar = (file) => ({
    type: 'FETCH_USER_UPDATE_AVATAR_REQUEST',
    payload: {
        url: Settings.url + '/api/user/avatar',
        init: {
            method: 'POST',
            // mode: 'no-cors',
         //  headers: {'Content-type': 'multipart/form-data'},
            body: file
        }
    }
});

export const fetchDeleteInviteUser = (userId, groupId) => ({
    type: 'FETCH_DELETE_INVITE_USER_REQUEST',
    payload: {
        url: Settings.url + '/api/user/invite',
        init: {
            method: 'DELETE',
            signal: AbortSignal.timeout(5000),
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                user_id: userId,
                group_id: groupId
            })
        }
    }
});
