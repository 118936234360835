import {Link} from "react-router-dom";
import styled from "styled-components";

import backArrow from "../img/arrow_left_15601.png"
import {GroupCardColor} from "./Color";
import {isMobile} from "./Const";

const NavContainer = styled.nav`
`

const Img = styled.img`
    height: 40px;
  transition: background-color .4s;
  &:hover {
    ${isMobile === false ? "background-color: " + GroupCardColor.backgroundColor + ";" : ""}
  }
  &:active {
    background-color: ${GroupCardColor.active};
  }
`

const ArrowBack = ({func}) => {
    return (
        <NavContainer>
            {/*<Link to={"/"}>*/}
                <Img src={backArrow} onClick={() => func()}/>
            {/*</Link>*/}
        </NavContainer>
    );

}

export default ArrowBack
