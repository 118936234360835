import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.css'
import './index.css';
import './global.css'
import reportWebVitals from './reportWebVitals';
import RoutNavigation from "./RoutNavigation";
import {Provider} from "react-redux";
import {store} from "./store/Store";
import ModalWindowGroup from "./modal/ModalWindowGroup";
import ModalWindowPurchase from "./modal/ModalWindowPurchase";
import ModalWindowError from "./modal/ModalWindowError";
import ModalWindowInvite from "./modal/ModalWindowInvite";
import ModalWindowWarning from "./modal/ModalWindowWarning";
import LoadWindow from "./modal/LoadWindow";
import ModalWindowChooseGroup from "./modal/ModalWindowChooseGroup";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<React.StrictMode>
    <Provider store={store}>
            {/*<Header/>*/}
            <RoutNavigation/>
            <ModalWindowGroup/>
            <ModalWindowPurchase/>
            <ModalWindowError/>
            <ModalWindowInvite/>
            <ModalWindowWarning/>
            <ModalWindowChooseGroup/>
            <LoadWindow/>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
