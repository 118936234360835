import styled from "styled-components";
import {Button, H1, Input, TextArea} from "../common/style/Style";
import {isMobile} from "../common/Const";

export const ModalContent = styled.div`
  position: relative;
  padding: 30px 20px 20px 20px;
  max-width: max-content;
  min-width: 440px;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 480px) {
    min-width: 200px;
    font-size: 18px;
    font-weight: bold;
  }
`
export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 10px;
  
  h3 {
    margin-bottom: 10px;
    align-self: start;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
`

export const InputField = styled(Input)`
  margin-bottom: 10px;
  width: 100%;
  font-size: 14px;
`

export const TextAreaField = styled(TextArea)`
  margin-bottom: 10px;
  width: 100%;
  font-size: 18px;
  //max-height: 27px;
  overflow-y: hidden;
  white-space: pre-wrap;
  ${props => (props.heightField == true ) ? "max-height: 50px; white-space: normal;" : "max-height: 32px; white-space: nowrap;"};
  @media (max-width: 480px) {
    font-size: 18px;
    ${props => (props.heightField == true ) ? "max-height: 50px; white-space: normal;" : "max-height: 32px; white-space: nowrap;"};
  }
`

export const ModalButton = styled(Button)`
  max-width: max-content;
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;
`

export const ModalText = styled(H1)`
  font-size: 14px;
  margin-bottom: 25px;
  max-width: max-content;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 16px;
    font-weight: bold;
  }
`
