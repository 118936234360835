import styled from "styled-components";
import {HeaderMenuColor} from "../../common/Color";
import {useDispatch} from "react-redux";
import {checkAllPurchaseInGroup, deleteAllCheckedPurchase} from "../../store/actions/api/actionApiPurchase";
import {showModalChooseGroup} from "../../store/actions/actionModal";
import {showModalWarning} from "../../store/actions/actionModalWarning";


const Menu = styled.div`
  position: absolute;
  transition: opacity .4s, visibility .4s, max-height .4s;
  ${props => props.show === true ? "visibility: visible; max-height: 100px;" : "visibility: hidden; max-height: 0;"};
  background-color: ${HeaderMenuColor.backgroundColor};
  box-shadow: 0px 0px 9px -1px rgba(34, 60, 80, 0.6);
  border-radius: 5px;
  top: 100%;
  right: 0;
  max-width: 280px;
  overflow: hidden;
  z-index: 9000;

  ul {
    margin: 0;
    padding: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`

const MenuItem = styled.li`
  margin: 0;
  padding: 3px 5px;
  display: flex;
  list-style-type: none;
  transition: background-color .4s;
  @media (max-width: 480px) {
    font-size: 18px;
  }
  p {
    color: ${HeaderMenuColor.colorText};
  }
  &:hover {
    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
    background-color: ${HeaderMenuColor.hover};
  }
  
  &:focus-visible {
    box-shadow: 0px 5px 10px 3px rgba(34, 60, 80, 0.2);
    background-color:  ${HeaderMenuColor.focusVisible};;
    outline: 0;
  }
`

const PurchaseListActionMenu = ({show, groupId, purchaseIds, func}) => {
    const dispatch = useDispatch();


    const del = () => {
        dispatch(deleteAllCheckedPurchase(groupId))
    }

    return (
        <Menu show={show}>
        <ul>
            <MenuItem onClick={() => {dispatch(showModalWarning(del, 'Удалить выделенные позиции?')); func(false);}}>Удалить выделенное</MenuItem>
            <MenuItem onClick={() => {dispatch(checkAllPurchaseInGroup(groupId)); func(false);}}>Выделить все</MenuItem>
            <MenuItem onClick={() => {dispatch(showModalChooseGroup(groupId, purchaseIds)); func(false);}}>Переместить выделенное</MenuItem>
        </ul>
    </Menu>
    )

}
export default PurchaseListActionMenu
