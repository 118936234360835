import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {closeModalGroup, closeModalPurchase} from "../store/actions/actionModal";
import {store} from "../store/Store";
import {useEffect, useRef, useState} from "react";
import {addPurchase, deletePurchase, editPurchase} from "../store/actions/api/actionApiPurchase";
import {CloseWindow, Modal} from "../common/CommonStyle";
import {isMobile, pressKeyEnter, pressKeyEsc} from "../common/Const";
import {ButtonGroup, InputField, ModalButton, ModalContent, ModalContentContainer, TextAreaField} from "./ModalStyle";
import {H3, TextArea} from "../common/style/Style";

const ModalWindowPurchase = () => {
    const dispatch = useDispatch();
    const {modal, user} = useSelector(() => store.getState())
    const [text, setText] = useState('');
    const textInput = useRef(null);

    useEffect(() => {
        if (Array.isArray(modal) || modal.show === false) {
            return;
        }
        if ( modal.data?.text === "") {
            setText("");
        } else  {
            setText(modal.data?.name);
        }
        textInput.current?.focus();
    }, [modal])

    const inputHandler = (e) => {
        setText(e.target.value)
    }


    const update = (e) => {
        e.stopPropagation();
        let purchase = {};
        purchase.id = modal.data.id;
        purchase.groupId = modal.data.groupId;
        purchase.name = text;
        purchase.userId = user.id
        purchase.checked = modal.data.checked

        dispatch(editPurchase(purchase));
        dispatch(closeModalPurchase());
    }

    const del = (e) => {
        dispatch(deletePurchase(modal.data.id));
        dispatch(closeModalPurchase());
    }
    const save = (e) => {
        let purchase = {};
        purchase.groupId = modal.data.groupId;
        purchase.name = text;
        purchase.date = new Date();
        purchase.userId = user.id

        dispatch(addPurchase(purchase));
        dispatch(closeModalPurchase());
    }

    const title = () => {
        let text = modal.data.id == undefined ? "Новое дело" : "Редактирование"
        return <H3>{text}</H3>
    }

    if (Array.isArray(modal)) {
        return null;
    }
    if (Array.isArray(modal.data) || modal.data == undefined) {
        return null;
    }
    return (
        <Modal key={modal.data.id}
               show={modal.show && modal.type === "MODAL_PURCHASE"}
               onKeyPress={e => pressKeyEnter(e, modal.data.text === "" ? () =>save(e): () => update(e))}
               onKeyDown={e => pressKeyEsc(e, () => dispatch(closeModalPurchase()))}
        >
            <ModalContent>
                <CloseWindow onClick={() => dispatch(closeModalPurchase())}>х</CloseWindow>
                <ModalContentContainer>
                    {title()}
                    <TextAreaField
                        heightField = {text != null && text.length > 18}
                        ref={textInput}
                        type="text"
                        value={text}
                        onChange={inputHandler}
                    />
                    <ButtonPanel isNew={modal.data.text === "" || modal.data == ""} save={save} update={update} del={del}/>
                </ModalContentContainer>
            </ModalContent>
        </Modal>
    )
}

export default ModalWindowPurchase;

const ButtonPanel = ({isNew ,save, del, update}) => {
    if (isNew) {
        return (
            <ButtonGroup isNew={isNew}>
                <ModalButton onClick={save}>Сохранить</ModalButton>
            </ButtonGroup>
        )
    }
    return (
        <ButtonGroup>
            {/*{isMobile && <ModalButton onClick={del}>Удалить</ModalButton> }*/}
            <ModalButton onClick={update}>Сохранить</ModalButton>
        </ButtonGroup>
    )
}
