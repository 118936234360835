
export const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

export const abortSignal = 50000;


export const pressKeyEnter = (e, onClick) => {
    if(e.key === 'Enter'){
        onClick();
    }
}

export const pressKeyEsc = (e, onClick) => {
    if (e.key === 'Escape'){
        onClick();
    }
}
