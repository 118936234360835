
export const timeZoneMap = [
    {zone: "-12:00", text: "(UTC-12:00) Линия перемены дат"},
    {zone: "-11:00", text: "(UTC-11:00) Время в формате UTC -11"},
    {zone: "-10:00", text: "(UTC-10:00) Алеутские острова, Гавайи"},
    {zone: "-09:30", text: "(UTC-09:30) Маркизские острова"},
    {zone: "-09:00", text: "(UTC-09:00) Аляска"},
    {zone: "-08:00", text: "(UTC-08:00) Нижняя Калифорния, Тихоокеанское время (США и Канада)"},
    {zone: "-07:00", text: "(UTC-07:00) Yukon, Аризона, Ла-Пас, Мазатлан, Чихуахуа"},
    {zone: "-06:00", text: "(UTC-06:00) Гвадалахара, Мехико, Монтеррей, США и Канада"},
    {zone: "-05:00", text: "(UTC-05:00) Богота, Кито, Лима, Рио-Бранко, Гавана, Гаити, Индиана"},
    {zone: "-04:00", text: "(UTC-04:00) Атлантическое время (Канада), Сантьяго"},
    {zone: "-03:30", text: "(UTC-03:30) Ньюфаундленд"},
    {zone: "-03:00", text: "(UTC-03:00) Бразилия, Буэнос-Айрес, Сальвадор, Гренландия"},
    {zone: "-02:00", text: "(UTC-02:00) Время в формате UTC -02"},
    {zone: "-01:00", text: "(UTC-01:00) Азорские о-ва, Кабо-Верде"},
    {zone: "+00:00", text: "(UTC+00:00) Дублин, Эдинбург, Лиссабон, Лондон"},
    {zone: "+01:00", text: "(UTC+01:00) Амстердам, Берлин, Вена, Рим, Париж, Прага"},
    {zone: "+02:00", text: "(UTC+02:00) Калининград, Киев, Хельсинки"},
    {zone: "+03:00", text: "(UTC+03:00) Москва, Санкт-Петербург, Волгоград"},
    {zone: "+04:00", text: "(UTC+04:00) Ижевск, Самара, Астрахань, Абу-Даби"},
    {zone: "+05:00", text: "(UTC+05:00) Екатеринбург, Ашхабад, Ташкент"},
    {zone: "+05:30", text: "(UTC+05:30) Колката, Мумбаи, Нью-Дели, Ченнай"},
    {zone: "+05:45", text: "(UTC+05:45) Катманду"},
    {zone: "+06:00", text: "(UTC+06:00) Омск, Астана, Дакка"},
    {zone: "+06:30", text: "(UTC+06:30) Янгон"},
    {zone: "+07:00", text: "(UTC+07:00) Красноярск, Новосибирск, Томск, Бангкок"},
    {zone: "+08:00", text: "(UTC+08:00) Иркутск, Гонконг, Пекин"},
    {zone: "+08:45", text: "(UTC+08:45) Юкла"},
    {zone: "+09:00", text: "(UTC+09:00) Якутск, Пхеньян, Осака, Токио"},
    {zone: "+09:30", text: "(UTC+09:30) Аделаида, Дарвин"},
    {zone: "+10:00", text: "(UTC+10:00) Владивосток, Канберра, Сидней"},
    {zone: "+10:30", text: "(UTC+10:30) Лорд-Хау"},
    {zone: "+11:00", text: "(UTC+11:00) Магадан, Сахалин"},
    {zone: "+12:00", text: "(UTC+12:00) Анадырь, Петропавловск-Камчатский, Фиджи"},
    {zone: "+12:45", text: "(UTC+12:45) Чатем"},
    {zone: "+13:00", text: "(UTC+13:00) Нукуалофа, Самоа"},
    {zone: "+14:00", text: "(UTC+14:00) О-в Киритимати"},
]

export const getTimeZoneText = (zone) => {
    let timezone = timeZoneMap.filter(timeZone => timeZone.zone === zone);
    if (timezone.length === 0) {
        return null;
    }
    return timezone[0].text;
}
export const getTimeZoneZone = (text) => {
    let timezone = timeZoneMap.filter(timeZone => timeZone.text === text);
    if (timezone.length === 0) {
        return null;
    }
    return timezone[0].zone;
}

const executeHourAndMinute = (zone) => {
    let hour = 0;
    let minute = 0;
    if (zone.charAt(0) === '+') {
        hour = '+' + zone.substring(1, 3);
        minute = '+' + zone.substring(4, 6);
        return {hour: hour, minute: minute}
    }
    hour = '-' + zone.substring(1, 3);
    minute = '-' + zone.substring(4, 6);
    return {hour: hour, minute: minute}
}

export const getTimeZoneHourAndMinute = (zone) => {
    let timezone = timeZoneMap.filter(timeZone => timeZone.zone === zone);
    if (timezone.length === 0) {
        return {hour: 0, minute: 0};
    }
     return executeHourAndMinute(timezone[0].zone);
}
