import {store} from "../store/Store";
import {showModalError} from "../store/actions/actionModal";
import moment from "moment";
import {fetchToken} from "../store/actions/api/actionApiRegistration";
import {abortSignal} from "../common/Const";
import {closeLoading, showLoading} from "../store/actions/actionLoading";

export async function fetchRequestRegistration(payload, fun) {
    const {url, init} = payload;
    init.signal = AbortSignal.timeout(abortSignal)
    return await fetch(url, init)
        .then((response) => {
            if (response.status === 200) {
                response.json().then(result => {
                    let link = null
                    if (localStorage.getItem("access_token") == null) {
                        link = "/"
                    }
                    localStorage.setItem("access_token", result.access_token)
                    localStorage.setItem("refresh_token", result.refresh_token)
                    localStorage.setItem("expire_in", moment().add(result.expire, 'second').toString())
                    localStorage.setItem("token_type", result.token_type)
                    localStorage.setItem("lock", false);

                    if (link != null) {
                        window.location.replace("/")
                    }
                    if (typeof fun === 'function') {
                        fun();
                    }
                    ;
                })
                return;
            }
            if (response.status === 404) {
                response.json().then(result => {
                    if (localStorage.getItem("expire_in") == null) {
                        store.dispatch({type: "ENTRY_MESSAGE", payload: "Неверный логин или пароль"});
                    } else {
                        window.location.replace("/auth");
                    }
                })
                return;

            }
            if (response.status === 400) {
                response.json().then(result => {
                    store.dispatch(showModalError(result.message));
                })
                return;
            }
            store.dispatch(showModalError("Ошибка сервера"));

        })
        .catch((err) => {
            store.dispatch(showModalError(err));
        });

}

const successReg = new RegExp("2\\d{2}");
const errorReg = new RegExp("4\\d{2}");

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));


export async function load (){
}
export async function fetchRequest(payload, fun) {
    const lock = localStorage.getItem("lock");
    load().then(() => store.dispatch(showLoading()));
    if (lock === "true") {
        await sleep(1000);
    }
    const tokenData = localStorage.getItem("expire_in");
    if (tokenData == null || tokenData == 'undefined') {
        window.location.replace("/auth")
    }
    if (moment(tokenData).isBefore(moment.now())) {
        localStorage.setItem("lock", true);
        let cred = {};
        cred.type = 'refresh_token';
        cred.token = localStorage.getItem("refresh_token")
        fetchRequestRegistration(fetchToken(cred).payload, () => fetchRequestContext(payload, fun))
    } else {
        return fetchRequestContext(payload, fun);
    }

}


export async function fetchRequestContext(payload, fun) {
    const {url, init} = payload;
    init.signal = AbortSignal.timeout(abortSignal)
    let headers = init.headers;
    init.headers = {
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token")
    }
    Object.assign(init.headers, headers);

    return await fetch(url, init)
        .then((response) => {
            store.dispatch(closeLoading());
            if (response.status === 200) {
                response.json().then(result => store.dispatch(fun(result)));
                return;
            }
            if (successReg.test(response.status)) {
                store.dispatch(fun);
                return;
            }
            if (response.status == 403) {
                store.dispatch(showModalError("Отказано в доступе"));
                return;
            }
            if (response.status == 401) {
                localStorage.clear();
                window.location.replace("/auth")
                return;
            }
            if (errorReg.test(response.status)) {
                // console.log(response.json())
                response.json().then(result => store.dispatch(showModalError(result.message)));
                return;
            }
            store.dispatch(showModalError("Сервер сейчас не доступен. Попробуйте позднее"));
            // return null;
        })
        .catch((err) => {
            store.dispatch(closeLoading());
            store.dispatch(showModalError(err));
            //   return {id: 1, img: "https://cdn.fishki.net/upload/post/201408/25/1297423/20.jpg", name: "Вася"}
        });
}

