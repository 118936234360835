import styled from "styled-components";
import {useNavigate, useParams} from "react-router";
import {ButtonStyle, InputStyle, Modal, RegistrationContainer, Window} from "../common/CommonStyle";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../store/Store";
import {fetchToken} from "../store/actions/api/actionApiRegistration";
import {useEffect, useState} from "react";
import {pressKeyEnter} from "../common/Const";

export const FormLogin = styled.div`
  position: relative;
  max-width: min-content;
  padding-top: 10px;
  width: 250px;
  align-self: center;
  display: flex;
  flex-direction: column;

  h2 {
    max-width: max-content;
    margin: 0 0 5px 0;
    font-size: 16px;
    @media (max-width: 480px) {
      font-size: 20px;
    }

  }

  input {
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  p {
    position: absolute;
    top: -20px;

    font-size: 12px;
    color: red;
    @media (max-width: 480px) {
      font-size: 14px;
      top: -25px;
    }
  }

  button {
    align-self: center;
    width: 150px;
    padding: 5px;
  }
`

export const RegistrationLink = styled.div`
  font-size: 15px;
  align-self: center;
  color: blue;
  cursor: pointer;
  transition: scale .2s, border-bottom-color .2s;
  border-bottom: solid 1px transparent;
  @media (max-width: 480px) {
    font-size: 18px;
  }

  &:hover {
    scale: 1.1;
    border-bottom: solid 1px blue;

  }
`
const Login = () => {
    const nav = useNavigate()
    const {registration} = useSelector(() => store.getState());
    const dispatch = useDispatch();
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        setPassword("");
        setLogin("");
       localStorage.clear();
    }, [registration])

    function entry(e) {
        let cred = {};
        cred.type = 'password';
        cred.username = login;
        cred.password = password;
        dispatch(fetchToken(cred))
    }

    return (
        <Window>
            <RegistrationContainer>
                <FormLogin>
                    <p>{registration}</p>
                    <label>
                        <h2>Логин</h2>
                        <InputStyle
                            placeholder={"Логин"}
                            pattern={"([a-Az-Z])+"}
                            name={"login"}
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            onKeyPress={e => pressKeyEnter(e, entry)}
                        />
                    </label>
                    <label>
                        <h2>Пароль</h2>
                        <InputStyle
                            placeholder={"Пароль"}
                            pattern={"[a-Az-Z]([^\s])"}
                            value={password}
                            name={"password"}
                            type={"password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyPress={e => pressKeyEnter(e, entry)}
                        />
                    </label>
                    <ButtonStyle
                        onKeyPress={e => pressKeyEnter(e, entry)}
                        onClick={entry}>Войти</ButtonStyle>
                    <RegistrationLink onClick={e => nav("/registration")}>Регистрация</RegistrationLink>
                </FormLogin>
            </RegistrationContainer>
        </Window>
    )
}

export default Login
