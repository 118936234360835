import {Container, TitleStyle} from "../../common/CommonStyle";
import {useNavigate, useParams} from "react-router-dom";
import PurchaseCard from "../card/PurchaseCard";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../../store/Store";
import React, {useEffect, useRef, useState} from "react";
import {showModalAddPurchase} from "../../store/actions/actionModal";
import {fetchGroupById} from "../../store/actions/api/actionApiGroup";
import {fetchPurchase} from "../../store/actions/api/actionApiPurchase";
import {Link} from "../../common/Link";
import {Button} from "../../common/style/Style";
import {isMobile} from "../../common/Const";
import {AddPurchaseColor, MainColor} from "../../common/Color";
import {clearPurchaseAction} from "../../store/actions/actionPurchace";
import PurchaseListActionMenu from "./PurchaseListActionMenu";
import {selectGroupById} from "../../store/selectors/selectors";
import ContextMenu from "../../common/contextMenu/ContextMenu";
import {ContextMenuClass} from "../../common/contextMenu/ContextMenuClass";
import ArrowBack from "../../common/ArrowBack";


export const PurchaseListUl = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  overflow: auto;

  li {
    margin: 0;
    padding: 0 5px 0 0px;
    list-style-type: none;
    &:last-child {
      margin-bottom: 2px;
    }
  }
`
const PurchaseListAction = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  div {
    align-self: center;
  }
`

const PurchaseListMenu = styled.div`
  font-size: 14px;
  padding: 1px 10px;
  border-radius: 10px;
  font-size: 18px;

  &:hover {
    background-color: ${MainColor.hover};
  }

  &:active {
    background-color: ${MainColor.active};
  }


`
const PurchaseListTitle = styled(TitleStyle)``

export const PurchaseListContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
`

export const PurchaseButton = styled(Button)`
  background-color: ${AddPurchaseColor.backgroundColor};
  font-size: 14px;
  font-weight: 600;
  color:  ${AddPurchaseColor.colorText};
  margin-bottom: 10px;
  box-shadow: ${AddPurchaseColor.boxShadow};
  max-width: max-content;

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &:hover {
    background-color: ${AddPurchaseColor.hover};
    ${isMobile === true ? "background-color: " + AddPurchaseColor.backgroundColor + ";" : ""}
  }

  &:active {
    background-color: ${AddPurchaseColor.active};
  }

  &:focus-visible {
    background-color: ${AddPurchaseColor.focusVisible};
  }
`

const Purchases = ({purchase, setContext}) => {
    if (purchase.length > 0) {
        return purchase.map(card => {
            return (
                <li>
                    <PurchaseCard key={card.id} card={card} setContext={setContext}/>
                </li>)
        });
    }
}

const PurchaseList = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {groups, purchase} = useSelector(() => store.getState());
    const group = useSelector(selectGroupById(id));

    const [show, setShow] = useState(false);
    const [context, setContext] = useState(ContextMenuClass)

    const purchaseMenuRef = useRef(null);


    function handleBack() {
        dispatch(clearPurchaseAction());
        navigate("/");
    }

    useEffect(() => {
        if (group === null) {
            dispatch(fetchGroupById(id));
        }
        dispatch(fetchPurchase(id))
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    }, [])

    const handleOutsideClick = (event) => {
       if (event && event.target !== null && purchaseMenuRef && purchaseMenuRef.current !== null && !purchaseMenuRef.current.contains(event.target)) {
            setTimeout(() => {
                setShow(false)
            }, 1)
       }
    }

    return (
        <PurchaseListContainer key={group?.id || groups.id}>
            <h1 hidden={true}>Список дел</h1>
            <PurchaseListAction ref={purchaseMenuRef}>
                {/*<ArrowBack onClick={e => handleBack(e)}/>*/}
                <ArrowBack func={handleBack}/>
                {/*<Link*/}
                {/*    name={"Назад"}*/}
                {/*    onClick={handleBack}/>*/}
                <PurchaseListMenu onClick={() => setShow(true)}>•••</PurchaseListMenu>
                {/*<Link*/}
                {/*    name={"Удалить выполненное"}*/}
                {/*    onClick={() => {dispatch(deleteAllCheckedPurchase(groups.id));}}/>*/}
                <PurchaseListActionMenu show={show} groupId={group?.id || groups.id} purchaseIds={purchase.filter(purchase => purchase.checked === true).map(p => p.id)} func={setShow}/>
            </PurchaseListAction>
            <PurchaseListTitle>{group?.name || groups.name}</PurchaseListTitle>
            <PurchaseButton onClick={() => dispatch(showModalAddPurchase(id))}>+ Добавить дело</PurchaseButton>

            <PurchaseListUl>
                <Purchases purchase={purchase} setContext={setContext} />
            </PurchaseListUl>
            <ContextMenu key={"menu"} context={context} setContext={setContext}/>

        </PurchaseListContainer>
    );
}
export default PurchaseList
