import styled from "styled-components";
import Basket from "../components/Bascket";
import {useSelector} from "react-redux";
import {store} from "../../store/Store";


const PeopleCardContainer = styled.article`
  padding: 2px 10px;
  margin: 0;
  display: flex;
  background-color: #ace3f1;
  border-radius: 10px;
  width: 100%;
  //height: 30px;
  align-items: center;
  gap: 10px;
  
  img {
    border-radius: 100%;
    width: 25px;
    height: 25px;
  }

`
const PeopleCardText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const PeopleCardText2 = styled(PeopleCardText)`
  font-size: 10px;
  color: gray;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`

const PeopleCardNameContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`

const PeopleCardToolBar = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  gap: 3px;
`

const PeopleCard = ({user, onDel, group}) => {

    const userAuthId = useSelector(() => store.getState()).user.id;
    function deleteUser(e) {
        e.stopPropagation();
        onDel(user);
    }

    return (
        <PeopleCardContainer>
            <img src={user.img}/>
            <PeopleCardNameContainer>
                <PeopleCardText>{user.name} </PeopleCardText>
                <PeopleCardText2>{user.login}</PeopleCardText2>
            </PeopleCardNameContainer>
            {((group.userId !== user.id && group.userId === userAuthId) || (group.userId !== userAuthId && userAuthId === user.id)) &&
                <PeopleCardToolBar>
                    <Basket click={deleteUser}/>
                </PeopleCardToolBar>
            }
        </PeopleCardContainer>
    )
}
export default PeopleCard
