import styled from "styled-components";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {InputStyle, RegistrationContainer, Window} from "../common/CommonStyle";
import {FormLogin, RegistrationLink} from "./Login";
import {useDispatch, useSelector} from "react-redux";
import {fetchRegistration, fetchToken} from "../store/actions/api/actionApiRegistration";
import {showModalError} from "../store/actions/actionModal";
import {store} from "../store/Store";


const Content = styled(FormLogin)`
  gap: 10px;
  display: grid;

  label {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 0px;

    h2 {
      margin: 0 7px 0 0;
      align-self: center;
      font-weight: normal;
      @media (max-width: 480px) {
        font-size: 20px;
      }
    }

    input {
      width: 180px;
      margin: 0;
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }

  div {
    max-width: min-content;
    margin-top: 0px;
    justify-self: center;
  }
`


const Registration = () => {
    const nav = useNavigate();
    const [login, setLogin] = useState("")
    const [invalid, setInvalid] = useState(false)
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const dispatch = useDispatch();

    const {modal} = useSelector(() => store.getState())

    useEffect(() => {
        if (modal.type === "MODAL_ERROR" && modal.show === true) {
            setLogin("");
            setInvalid(true);
        }
    }, [modal])

    function registrationFunc(e) {
        if (isValidDate()) {
            let cred = {};
            cred.type = 'password';
            cred.username = login;
            cred.password = password;
            cred.name = name;
            cred.email = email;
            dispatch(fetchRegistration(cred))
        }
    }

    function isValidDate() {
        let flag = true;
        if (login === "") {
            flag = false;
        }
        if (name === "") {
            flag = false;
        }
        if (password === "") {
            flag = false;
        }
        if (flag === false) {
            dispatch(showModalError("Ошибка ввода данных. Заполните обязательные поля"))
            setInvalid(true)
        }
        return flag;
    }

    return (
        <Window>
            <RegistrationContainer>
                <Content>
                    <label>
                        <h2>Логин:</h2>
                        <InputStyle
                            placeholder={"Логин"}
                            pattern={"([a-Az-Z])+"}
                            name={"login"}
                            value={login}
                            error={login === "" && invalid || login === null}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                    </label>
                    <label>
                        <h2>Пароль:</h2>
                        <InputStyle
                            placeholder={"Пароль"}
                            pattern={"[a-Az-Z]([^\s])"}
                            name={"password"}
                            type={"password"}
                            error={password === "" && invalid}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </label>
                    <label>
                        <h2>Имя:</h2>
                        <InputStyle
                            placeholder={"Имя"}
                            name={"name"}
                            error={name === "" && invalid}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <label>
                        <h2>Email:</h2>
                        <InputStyle
                            placeholder={"email"}
                            name={"email"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </label>
                    <RegistrationLink onClick={registrationFunc}>Зарегистрироваться</RegistrationLink>

                </Content>
            </RegistrationContainer>
        </Window>
    )
}

export default Registration;
