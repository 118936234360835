
export const findGroupByIdAction = (id) => ({
    type: 'FIND_GROUP_BY_ID',
    payload: id
})


export const fetchGroupAction = (group) => ({
    type: "FETCH_GROUP",
    payload: group
})

export const deleteGroupByIdAction = (id) => ({
    type: "DELETE_GROUP_BY_ID",
    payload: id
})

export const addGroupAction = (group) => ({
    type: "ADD_GROUP",
    payload: group
})

export const incCountUserInGroupAction = (groupId) => ({
    type: "INC_COUNT_USER_IN_GROUP",
    payload: groupId
})

export const decrCountUserInGroupAction = (groupId) => ({
    type: "DECR_COUNT_USER_IN_GROUP",
    payload: groupId
})

export const editGroupAction = (group) => ({
    type: "EDIT_GROUP",
    payload: group
})
