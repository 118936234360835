import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {closeModalGroup, showModalInvitePeople} from "../store/actions/actionModal";
import {store} from "../store/Store";
import {useEffect, useRef, useState} from "react";
import {addGroup, deleteGroup, editGroup} from "../store/actions/api/actionApiGroup";
import {CloseWindow, Modal} from "../common/CommonStyle";
import {isMobile, pressKeyEnter, pressKeyEsc, pressKeyEsq} from "../common/Const";
import {ButtonGroup, ModalButton, ModalContent, ModalContentContainer, TextAreaField} from "./ModalStyle";
import { H3 } from "../common/style/Style";
import Checkbox from "../main/components/Checkbox";
import {Settings} from "../settings";
import Linkify from "react-linkify";


const People = styled.div`
  margin: 0 0 10px 0;
  padding: 0;
  color: blue;
  border-bottom: 1px solid transparent;

  display: flex;
  gap: 2px;
  align-self: start;

  transition: border-bottom-color .4s;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid blue;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  span {
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`
const LinkUrl = styled.p`
  color: blue;
  font-size: 14px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const CheckBoxContainer = styled.div`
  align-self: start;
  margin-bottom: 10px;
  label {
    margin-bottom: 10px;
    div {
      top: 0;
      width: 100%;

      &:before {
        top: 2px;
      }

      svg {
        top: 2px;
      }
    }
  }
`

const ModalWindowGroup = () => {
    const dispatch = useDispatch();
    const {modal} = useSelector(() => store.getState())
    const [text, setText] = useState('');
    const [settingsGroup, setSettingsGroup] = useState({enablePublic: false, enablePublicCheck: false, enablePublicAdd: false});
    const textInput = useRef(null);

    useEffect(() => {
        if (Array.isArray(modal) || modal.show === false) {
            return;
        }
        if (modal.data?.name === undefined) {
            setText("");
        } else {
            setText(modal.data.name);
            setSettingsGroup({enablePublic: modal.data.enablePublic, enablePublicCheck: modal.data.enablePublicCheck, enablePublicAdd: false})
        }
        textInput.current?.focus();
    }, [modal]);
    const inputHandler = (e) => {
        setText(e.target.value)
    }

    const update = (e) => {
        let group = modal.data;
        group.name = text;
        group.publicGroup = modal.data.publicGroup;
        group.enablePublic = settingsGroup.enablePublic
        group.enablePublicCheck = settingsGroup.enablePublicCheck
        group.enablePublicAdd = settingsGroup.enablePublicAdd
        dispatch(editGroup(group));
        dispatch(closeModalGroup());
    }

    const del = (e) => {
        dispatch(deleteGroup(modal.data.id));
        dispatch(closeModalGroup());
    }
    const save = (e) => {
        let group = {};
        group.name = text;
        group.publicGroup = modal.data.publicGroup;
        group.userId = store.getState().user.id;
        dispatch(addGroup(group));
        dispatch(closeModalGroup());
    }

    const close = (e) => {
        dispatch(closeModalGroup());
    }

    const toCheckPublicGroup = (e) => {
        let settings = {
            enablePublic: !settingsGroup.enablePublic,
            enablePublicCheck: settingsGroup.enablePublicCheck,
            enablePublicAdd: settingsGroup.enablePublicAdd
        };
        setSettingsGroup(settings)
    }

    const toCheckPublicGroupCheck = (e) => {
        let settings = {
            enablePublic: settingsGroup.enablePublic,
            enablePublicCheck: !settingsGroup.enablePublicCheck,
            enablePublicAdd: settingsGroup.enablePublicAdd
        };
        setSettingsGroup(settings)
    }

    const toCheckPublicGroupAdd = (e) => {
        let settings = {
            enablePublic: settingsGroup.enablePublic,
            enablePublicCheck: settingsGroup.enablePublicCheck,
            enablePublicAdd: !settingsGroup.enablePublicAdd
        };
        setSettingsGroup(settings)
    }

    const title = () => {
        let text = modal.data.name == undefined ? "Новая группа" : "Редактирование группы"
        return <H3>{text}</H3>
    }

    if (Array.isArray(modal)) {
        return;
    }

    if (Array.isArray(modal.data) || modal.data == undefined) {
        return null;
    }

    return (
        <Modal key={modal.data.id}
               show={modal.show && modal.type === "MODAL_GROUP"}
               onKeyPress={e => pressKeyEnter(e, modal.data.name === undefined ? save: update)}
               onKeyDown={e => pressKeyEsc(e, () => close(e))}
        >
            <ModalContent>
                <CloseWindow onClick={close}>х</CloseWindow>
                <ModalContentContainer>
                    {title()}
                    <TextAreaField
                        heightField = {text !== null && text.length > 18}
                        ref={textInput}
                        type="text"
                        value={text}
                        onChange={inputHandler}
                    />
                    {modal.data.name &&
                        <>
                            <People onClick={() => dispatch(showModalInvitePeople(modal.data))}>
                                <p>Участников:&nbsp;</p>
                                <span>{modal.data.countUser}</span>
                            </People>
                            <CheckBoxContainer>
                                <Checkbox text={'Сделать публичным'}
                                          checked={settingsGroup.enablePublic}
                                          click={() => toCheckPublicGroup()}>

                                </Checkbox>
                                {settingsGroup.enablePublic &&
                                    <Checkbox text={'Разрешить выбор'}
                                              checked={settingsGroup.enablePublicCheck}
                                              click={() => toCheckPublicGroupCheck()}>

                                    </Checkbox>}
                                {settingsGroup.enablePublic &&
                                <Linkify>
                                    <LinkUrl>{Settings.urlUI + '/public/group/' + modal.data.id}</LinkUrl>
                                </Linkify>}
                            </CheckBoxContainer>
                        </>
                    }


                    <ButtonPanel isNew={modal.data.name === undefined} save={save} update={update} del={del}/>
                </ModalContentContainer>
            </ModalContent>
        </Modal>
    )
}

export default ModalWindowGroup;

const ButtonPanel = ({isNew, save, del, update}) => {
    if (isNew) {
        return (
            <ButtonGroup isNew={isNew}>
                <ModalButton onClick={save}>Сохранить</ModalButton>
            </ButtonGroup>
        )
    }
    return (
        <ButtonGroup>
            {/*{isMobile && <ModalButton onClick={del}>Удалить</ModalButton> }*/}
            <ModalButton onClick={update}>Сохранить</ModalButton>
        </ButtonGroup>
    )
}
