


export let ContextMenuClass = {
    show: false,
    locationX: 0,
    locationY: 0,
    fEdit: function () {},
    fDel: function () {},
    fMove: function () {},
    dat: {}

}
