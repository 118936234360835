

export const showModalGroup = (group) => ({
    type: 'SHOW_MODAL_GROUP',
    payload: group
});

export const showModalAddGroup = () => ({
    type: 'SHOW_MODAL_ADD_GROUP',
    payload: {id: Date()}
});

export const closeModalGroup = () => ({
    type: 'CLOSE_MODAL_GROUP',
})

export const showModalPurchase = (purchase) => ({
    type: 'SHOW_MODAL_PURCHASE',
    payload: purchase
});
export const showModalAddPurchase = (groupId) => ({
    type: 'SHOW_MODAL_ADD_PURCHASE',
    payload: {text: "", groupId: groupId}
});
export const closeModalPurchase = () => ({
    type: 'CLOSE_MODAL_PURCHASE',
})


export const showModalError = (error) => ({
    type: 'SHOW_MODAL_ERROR',
    payload: error
});
export const closeModalError = () => ({
    type: 'CLOSE_MODAL_ERROR',
})


export const showModalInvitePeople = (group) => ({
    type: 'SHOW_MODAL_INVITE_PEOPLE',
    payload: group
});
export const closeModalInvitePeople = () => ({
    type: 'CLOSE_MODAL_INVITE_PEOPLE',
})

export const showModalChooseGroup = (groupId, purchaseIds) => ({
    type: 'SHOW_MODAL_CHOOSE_GROUP',
    payload: {id: groupId, purchaseIds: purchaseIds}
});
export const closeModalChooseGroup = () => ({
    type: 'CLOSE_MODAL_CHOOSE_GROUP',
})
