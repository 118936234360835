import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../store/Store";
import {Modal} from "../common/CommonStyle";
import {closeModalWarning} from "../store/actions/actionModalWarning";
import {Button} from "../common/style/Style";
import {pressKeyEsc} from "../common/Const";
import {ModalContent, ModalContentContainer, ModalText} from "./ModalStyle";


const ModalContentWarning = styled(ModalContent)`
  padding: 0;
`

const ModalContentContainerWarning = styled(ModalContentContainer)`
  padding: 10px;
  margin: 0 10px;
`

const ModalHeader = styled(ModalContent)`
  padding: 10px;
  max-width: none;
  background-color: yellow;
  border-radius: 10px 10px 0px 0px;
`

const ModalButton = styled(Button)`
  width: 70px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
`

const ModalWindowWarning = () => {
    const dispatch = useDispatch();
    const {warning} = useSelector(() => store.getState())

    if (Array.isArray(warning) || warning.type != 'MODAL_WARNING') {
        return;
    }

    function yes(e) {
        warning.action();
        dispatch(closeModalWarning(warning.text));
    }

    function no(e) {
        dispatch(closeModalWarning(warning.text));
    }


    return (
        <Modal show={warning.show && warning.type === "MODAL_WARNING"}
               onKeyDown={e => pressKeyEsc(e, no)}
        >
            <ModalContentWarning>
                <ModalHeader>Внимание!</ModalHeader>
                <ModalContentContainerWarning>
                    <ModalText>{warning.text}</ModalText>
                    <ButtonGroup>
                        <ModalButton onClick={yes}>Да</ModalButton>
                        <ModalButton onClick={no}>Нет</ModalButton>
                    </ButtonGroup>
                </ModalContentContainerWarning>
            </ModalContentWarning>
        </Modal>
    )
}

export default ModalWindowWarning;



