

export default function reducerUserList(state = [], action) {
    switch (action.type) {
        case "FIND_USER_LIST_BY_HALF_NAME": {
            return action.payload;
        }

        case "CLEAR_USER_LIST": {
            return []
        }
        default:
            return state;
    }
}
