export default function reducerPurchase(state = [], action) {

    switch (action.type) {
        case "FETCH_PURCHASE": {
            return action.payload;
        }
        case "DELETE_PURCHASE_BY_ID": {
            return state.filter((purchase) => purchase.id !== action.payload);
        }

        case "DELETE_CHECKED_PURCHASE": {
            return state.filter((purchase) => purchase.checked !== true)
        }
        case "CLEAR_PURCHASE": {
            return [];
        }

        case "ADD_PURCHASE": {
            return [...state, action.payload];
        }
        case "EDIT_PURCHASE": {
            return state.map(purchase => {
                if (purchase.id == action.payload.id) {
                    return action.payload
                }
                return purchase;
            });
        }

        default:
            return state;
    }
}
