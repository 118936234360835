import {BrowserRouter, Route, Routes} from "react-router-dom";
import PurchaseList from "./main/purchase/PurchaseList";
import Main from "./main/Main";
import React from "react";
import Header from "./header/Header";
import Login from "./registration/Login";
import Registration from "./registration/Registration";
import Profile from "./profile/Profile";
import PasswordPage from "./profile/PasswordPage";
import PublicList from "./publicPage/PublicList";

const RoutNavigation = () => {
    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={<Header/>}>
                    <Route index element={<Main/>}/>
                    <Route path="profile" element={<Profile/>}/>
                    <Route path="password" element={<PasswordPage/>}/>
                    <Route path="group/:id" element={<PurchaseList/>}/>
                </Route>
                <Route  path={"/auth"} element={<Login/>}/>
                <Route  path={"/registration"} element={<Registration/>}/>
                <Route  path={"/public/group/:id"} element={<PublicList/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutNavigation
