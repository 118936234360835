
export const findUserAction = (user) => ({
    type: 'FIND_USER',
    payload: user
})

export const findUserListByHalfNameAction = (user) => ({
    type: 'FIND_USER_LIST_BY_HALF_NAME',
    payload: user
})

export const clearUserListAction = () => ({
    type: 'CLEAR_USER_LIST',
})

