

export class HeaderColor {
    static backgroundColor = '#0a0ab2';
    static colorText = '#FFFFFF';
    static focusVisible= '#0000FFFF';
}

export class HeaderMenuColor {
   static backgroundColor = '#ebeeee';
    static colorText = '#000000';
    static hover = '#6287f8';
    static focusVisible = '#6287f8';
}

export class MainColor {
    static backgroundColor = '#d7dada';
    static colorText = '#302b88';
    static hover = '#cacccc';
    static active = '#919293';
    static focusVisible = '#cacccc';
    static boxShadow = '0px 0px 1px 0px #000000';
  //  static boxShadow = '2px 3px 5px 2px #81dddd inset, 0px 0px 0px 0px #fafafa, 1px 1px 3px 0px #000000';

}

export class GroupCardColor {
    static backgroundColor = '#a5bbff';
    static backgroundColorSelf = '#9be3f0';
    static colorText = '#000000';
    static hover = '#6287f8';
    static hoverSelf = '#22d7f6';
    static active = '#4d6dd2';
    static activeSelf = '#1cb6d0';
    static focusVisible = this.hover;
    static focusVisibleSelf = this.hoverSelf;
   // static boxShadow = '2px 3px 5px 1px #FFFF inset, 0px 0px 0px 0px #fafafa, 1px 1px 3px 0px #000000';
    static boxShadow = '1px 3px 5px 1px rgba(255, 255, 255, 0.4) inset, 3px 1px 5px -2px rgba(34, 60, 80, 0.6)';
   // static boxShadowSelf = '2px 3px 5px 2px #8ff885 inset, 0px 0px 0px 0px #fafafa, 1px 1px 3px 0px #000000';
    static boxShadowSelf = '1px 3px 5px 1px rgba(255, 255, 255, 0.4) inset, 3px 1px 5px -2px rgba(34, 60, 80, 0.6)';
}


export class AddPurchaseColor {
    static backgroundColor = MainColor.backgroundColor;
    static colorText = MainColor.colorText;
    static hover = MainColor.hover;
    static active = MainColor.active;
    static focusVisible = MainColor.focusVisible;
    static boxShadow = MainColor.boxShadow;
 //   static boxShadow = '2px 3px 5px 2px #81dddd inset, 0px 0px 0px 0px #fafafa, 1px 1px 3px 0px #000000';
}


export class PurchaseCardColor {
    static backgroundColor = GroupCardColor.backgroundColor;
    static backgroundColorSelf = GroupCardColor.backgroundColorSelf;
    static colorText = GroupCardColor.colorText;
    static hover = GroupCardColor.hover;
    static hoverSelf = GroupCardColor.hoverSelf;
    static active = GroupCardColor.active;
    static activeSelf = GroupCardColor.activeSelf;
    static focusVisible = GroupCardColor.focusVisible;
    static focusVisibleSelf = GroupCardColor.focusVisibleSelf;

   // static boxShadow = '2px 3px 5px 2px #81dddd inset, 0px 0px 0px 0px #fafafa, 1px 1px 3px 0px #000000';
   // static boxShadowSelf = '2px 3px 5px 2px #8ff885 inset, 0px 0px 0px 0px #fafafa, 1px 1px 3px 0px #000000';
    static boxShadow = GroupCardColor.boxShadow;
    static boxShadowSelf = GroupCardColor.boxShadowSelf;
}
