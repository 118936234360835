import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {closeModalInvitePeople, showModalGroup} from "../store/actions/actionModal";
import {store} from "../store/Store";
import {useEffect, useState} from "react";
import {CloseWindow, Modal} from "../common/CommonStyle";
import PeopleCard from "../main/card/PeopleCard";
import {
    fetchDeleteInviteUser,
    fetchFindUserInGroupById,
    fetchInviteUser,
    fetchUserByHalfName
} from "../store/actions/api/actionApiUser";
import {clearUserListAction} from "../store/actions/actionUser";
import {addUserInUserListInGroupAction, deleteUserInUserListInGroupAction} from "../store/actions/actionUserListGroup";
import {
    deleteGroupByIdAction,
} from "../store/actions/actionGroup";
import {showModalWarning} from "../store/actions/actionModalWarning";
import {InputField, ModalButton, ModalContent, ModalContentContainer} from "./ModalStyle";

const SearchGroup = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  
  input {
    margin: 0;
    border-radius: 10px 0px 0px 10px;
    outline: 0;

    &:focus-visible {
      border: 1px solid blue;
      outline: 0;
      background-color: inherit;
    }
  }
  button {
    border-radius: 0px 10px 10px 0px;
    &:focus-visible {
      outline: 0;
      background-color: blue;
    }
  }
`

const PeopleList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const ResultPanelStyle = styled.div`
  position: absolute;
  width: 100%;
  border: ${props => props.visible === true ? "1px solid black" : "0"};
  //border-top: 0;
  background-color: white;
  top: 100%;
  border-radius: 10px;
`
const ModalWindowInvite = () => {
    const dispatch = useDispatch();
    const {modal, users, userInGroup, user} = useSelector(() => store.getState())
    const [text, setText] = useState('');
    const [selector, setSelector] = useState(false);
    const [chooseUser, setChooseUser] = useState({});

    let timer;

    useEffect(() => {
        if (Array.isArray(modal) || modal.show === false || modal.type != "MODAL_INVITE") {
            return;
        }
        dispatch(fetchFindUserInGroupById(modal.data.id))
    }, [modal, users])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
    })

    const handleOutsideClick = (event) => {
        if (event && event.target != null && selector) {
          setSelector(false);
        }
    }

    const inputHandler = (e) => {
        setText(e.target.value)
        clearTimeout(timer)
        if (e.target.value.length === 0) {
            setSelector(false);
            dispatch(clearUserListAction())
            return;
        }
        timer = setTimeout(() => dispatch(fetchUserByHalfName(e.target.value, modal.data.id)), 500)
        setSelector(true)
    }

    if (Array.isArray(modal)) {
        return null;
    }

    const onChoose = (val) => {
        setSelector(false);
        setText(val.name);
        setChooseUser(val);
        dispatch(clearUserListAction())
    }

    const invite = (e) => {
        dispatch(fetchInviteUser(chooseUser.id, modal.data.id))
        dispatch(addUserInUserListInGroupAction(chooseUser))
        setText("");
        setChooseUser(undefined);
    }


    const onDel = (val) => {
        dispatch(showModalWarning(() => onDel2(val), "Вы уверены, что хотите удалить " + val.name + " из группы?" ))
    }

    const onDel2 = (val) => {
        setSelector(false)
        dispatch(fetchDeleteInviteUser(val.id, modal.data.id));
        dispatch(deleteUserInUserListInGroupAction(val));

        if (user.id === val.id) {
            dispatch(deleteGroupByIdAction(modal.data.id));
            dispatch(closeModalInvitePeople());
        }

    }

    return (
        <Modal show={modal.show && modal.type === "MODAL_INVITE"}>
            <ModalContent>
                <CloseWindow onClick={() => {
                    dispatch(closeModalInvitePeople());
                    setChooseUser(undefined);
                    setText('');
                }}>х</CloseWindow>
                <ModalContentContainer>
                    <SearchGroup>
                        <InputField type="text" value={text} onChange={inputHandler}/>
                        <ModalButton onClick={invite} disabled={chooseUser === undefined || text.length == 0} >Добавить</ModalButton>
                        {selector && <ResultPanel users={users} onChoose={onChoose}></ResultPanel> }
                    </SearchGroup>
                    <Peoples users={userInGroup} group={modal.data} onDel={onDel}/>
                </ModalContentContainer>
            </ModalContent>
        </Modal>
    )
}
export default ModalWindowInvite;

const Peoples = ({users, onDel, group}) => {
    return (
        <PeopleList>
            {users.map(user =>
                <li>
                    <PeopleCard user={user} group={group} onDel={onDel}></PeopleCard>
                </li>
            )}
        </PeopleList>
    )
}


const ResultPanel = ({users, onChoose}) => {
    if (users === undefined) {
        return null;
    }

    return (
        <ResultPanelStyle visible={users.length > 0}>
            {users.length > 0 && users.map(user =>
                    <FindUser user={user} onChoose={onChoose} ></FindUser>
            )}
        </ResultPanelStyle>
    )
}


const FindUserContainer = styled.div`
  padding: 4px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  article {
    background-color: inherit;
    svg {
      visibility: hidden;
    }
  }
  &:hover {
    outline: 1px solid black;
    background-color: #61dafb;
  }
`

const FindUserText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
`

const FindUserText2 = styled(FindUserText)`
  font-size: 10px;
  color: gray;
`

const FindUser = ({user, onChoose}) => {
    return (
        <FindUserContainer onClick={() => onChoose(user)}>
            {/*<FindUserText>{user.name} </FindUserText>*/}
            {/*<FindUserText2>{user.login}</FindUserText2>*/}
            <PeopleCard user={user} group={{}}></PeopleCard>
        </FindUserContainer>
    )
}
