import {A} from "./style/Style";
import styled from "styled-components";




const Div= styled.div`
  margin: 0;
  padding: 0;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`

export const Link = ({name, onClick}) => {

    function press(e) {
        if(e.key === 'Enter'){
            onClick();
        }
    }

    return (
        <Div>
            <A onClick={onClick} tabindex={0} onKeyPress={press}>{name}</A>
        </Div>
    )
}
