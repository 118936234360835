
export const findPurchaseAction = (purchase) => ({
    type: 'FETCH_PURCHASE',
    payload: purchase
})

export const deletePurchaseByIdAction = (id) => ({
    type: 'DELETE_PURCHASE_BY_ID',
    payload: id
})

export const deleteCheckedPurchase = () => ({
    type: 'DELETE_CHECKED_PURCHASE'
})

export const addPurchaseAction = (purchase) => ({
    type: "ADD_PURCHASE",
    payload: purchase
})

export const editPurchaseAction = (purchase) => ({
    type: "EDIT_PURCHASE",
    payload: purchase
})

export const clearPurchaseAction = () => ({
    type: "CLEAR_PURCHASE",
})
