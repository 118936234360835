export default function reducerGroup(state = [], action) {

    switch (action.type) {
        case "FETCH_GROUP": {
            return action.payload;
        }
        case "FIND_GROUP_BY_ID": {

            if (state.length === 0 || !Array.isArray(state)) {
                return state
            }
           return state.filter(group => group.id == action.payload)[0];
        }
        case "DELETE_GROUP_BY_ID": {
            return state.filter(group => group.id != action.payload);
        }

        case "ADD_GROUP": {
            return [...state, action.payload];
        }
        case "INC_COUNT_USER_IN_GROUP": {
            state.forEach(group => {
                if (group.id == action.payload) {
                    group.countUser = group.countUser + 1
                }
            })
            return state;
        }

        case "DECR_COUNT_USER_IN_GROUP": {
            state.forEach(group => {
                if (group.id == action.payload) {
                    group.countUser = group.countUser - 1
                }
            })
            return state;
        }

        case "EDIT_GROUP": {
            return state.map(group => {
                if (group.id == action.payload.id) {
                    return action.payload
                }
                return group;
            });
        }
        default:
            return state;
    }
}
