export default function reducerLoading(state = [], action) {
    switch (action.type) {
        case "SHOW_LOADING":
            return {show: true, type: "SHOW_LOADING"};

        case "CLOSE_LOADING":
            return {show: false, type: "CLOSE_LOADING"};

        default: return state;
    }
}
