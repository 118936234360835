import styled from "styled-components";
import {H1, H2} from "./style/Style";


export const Container = styled.div`
  padding: 0px 20px;
  @media (max-width: 680px) {
    padding: 0px 10px;
  }
`

export const ToolBarActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3px;
  @media (max-width: 760px) {
    display: none;
  }
`
export const Window = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity .5s, visibility .5s;
`

export const Modal = styled(Window)`
  background: rgba(0, 0, 0, 0.5);
  ${prop => {
    if (prop.show === true) {
        return "visibility: visible; " +
            "opacity: 1;"
    } else {
        return ' visibility: hidden; ' +
            'opacity: 0';
    }
}}
`

export const CloseWindow = styled.a`
  position: absolute;
  right: 2%;
  cursor: pointer;
  top: 0%;
  font-size: 25px;
  transition: color .4s;
  color: black;

  @media (max-width: 480px) {
    font-size: 25px;
  }

  &:hover {
    color: black;
  }
`
export const RegistrationContainer = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: center;

  outline: 1px solid black;
  background-color: #9da9f6;
  padding: 20px;
  outline: 5px solid #8695ef;
  border-radius: 10px;
  box-shadow: 7px 5px 5px #585859;;
`

export const InputStyle = styled.input`
  font-size: 20px;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  
  ${props => props.error === true ? "background-color: #ee9292;" : ""}
  
  transition: background-color .4s;
  outline: 0;
  border: 0;

  &:hover {
    background-color: #d2d3d3;
  }

  &:focus-visible {
    background-color: #cef6cc;
  }

  input:placeholder-shown {
    border-color: #cff3e8;
  }
`

export const ButtonStyle = styled.button`
    display: block;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    border: 0;
    outline: 1px solid black;
    transition: background-color .4s, outline .4s;
    background-color: white;
    cursor: pointer;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    font-size: 18px;
  }

    &:hover {
      background-color: #9c9cf5;
    }

    &:active {
      background-color: #3232f3;
    }
  &:focus-visible {
    outline: 1px solid blue;
  }
`

export const TitleStyle = styled(H2)`
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 25px;
  }
`
