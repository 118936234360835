import {useSelector} from "react-redux";
import {store} from "../store/Store";
import {Modal, Window} from "../common/CommonStyle";
import styled from "styled-components";
import {useEffect} from "react";
import {HeaderColor} from "../common/Color";


const Loading = styled(Modal)`
  z-index: 9000;
  transition: opacity .0s, visibility .0s;
  background: rgba(0, 0, 0, 0.0);
`
const Loader = styled.div`
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: ${HeaderColor.focusVisible};
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;


  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const LoadWindow = () => {
    const {loading} = useSelector(() => store.getState())

    useEffect(() => {
        if (loading.show === false) {
            return;
        }

    }, [loading])

    return (
        <Loading show={loading.show && loading.type === "SHOW_LOADING"}>
            <Loader/>
        </Loading>
    )
}
export default LoadWindow;
