export default function reducerModalWindow(state = [], action) {
    switch (action.type) {
        case "SHOW_MODAL_ADD_GROUP":
        case "SHOW_MODAL_GROUP":
            return {show: true, type: "MODAL_GROUP",  data: action.payload};

        case "CLOSE_MODAL_GROUP":
            return {show: false, type: "MODAL_GROUP", data: []};

        case "SHOW_MODAL_PURCHASE":
        case "SHOW_MODAL_ADD_PURCHASE":
            return {show: true, type: "MODAL_PURCHASE", data: action.payload};

        case "CLOSE_MODAL_PURCHASE":
            return {show: false, type: "MODAL_PURCHASE", data: []};

        case "SHOW_MODAL_ERROR":
            return {show: true, type: "MODAL_ERROR", data: action.payload};

        case "CLOSE_MODAL_ERROR":
            return {show: false, type: "MODAL_ERROR", data: state.data};

        case "SHOW_MODAL_INVITE_PEOPLE":
            return {show: true, type: "MODAL_INVITE", data: action.payload};

        case "CLOSE_MODAL_INVITE_PEOPLE":
            return {show: false, type: "MODAL_INVITE", data: state.data};

        case "SHOW_MODAL_CHOOSE_GROUP":
            return {show: true, type: "MODAL_CHOOSE_GROUP", data: action.payload};

        case "CLOSE_MODAL_CHOOSE_GROUP":
            return {show: false, type: "MODAL_CHOOSE_GROUP", data: state.data};

        default: return state;
    }
}
